import { IResourceComponentsProps, useCustom } from "@pankod/refine-core";
import React from "react";
import { AntdList, Skeleton, Row, Col } from "@pankod/refine-antd";
import ENV from "utils/env";
import { useDashboard } from "dashboardProvider";

export const GameCountsByCityList: React.FC<IResourceComponentsProps> = () => {
  const { selectedDashboard } = useDashboard();
  const { data: response, isFetching } = useCustom({
    url: `${ENV.API_URL}/api/campaign/trivia/playCountsBasedOnCities`,
    method: "get",
    config: {
      query: {
        dbkey: selectedDashboard,
      },
    },
  });

  if (isFetching) {
    return <Skeleton />;
  }

  const createDataSource = (start: number, end?: number) => {
    const results = response?.data?.results || [];
    const slicedResults = end
      ? results.slice(start, end)
      : results.slice(start);
    return slicedResults.map((item: any) => ({
      key: item.city,
      city: item.city,
      count: item.count,
    }));
  };

  const firstFiveDataSource = createDataSource(0, 5);
  const lastFiveDataSource = createDataSource(-5);

  const renderList = (dataSource: any[], title: string) => (
    <div style={{ padding: "0 16px" }}>
      <h3>{title}</h3>
      <AntdList
        dataSource={dataSource}
        renderItem={(item: any) => (
          <AntdList.Item>
            <AntdList.Item.Meta title={item.city} />
            <p style={{ fontWeight: 800, fontSize: 20 }}>{item.count}</p>
          </AntdList.Item>
        )}
      />
    </div>
  );

  return (
    <Row
      gutter={[
        { xs: 8, sm: 16, md: 24, lg: 32 },
        { xs: 8, sm: 16, md: 24, lg: 32 },
      ]}
    >
      <Col xs={24} sm={24} md={12}>
        {renderList(firstFiveDataSource, "En Çok Oyun Oynanan 5 Şehir")}
      </Col>
      <Col xs={24} sm={24} md={12}>
        {renderList(lastFiveDataSource, "En Az Oyun Oynanan 5 Şehir")}
      </Col>
    </Row>
  );
};
