import React from "react";
import ReactDOM from "react-dom";
import { TourProvider } from '@reactour/tour'
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock'

import App from "./App";
import "./i18n";

const disableBody = target => disableBodyScroll(target)
const enableBody = target => enableBodyScroll(target)
ReactDOM.render(
  <React.StrictMode>
    <React.Suspense fallback="loading">
      <TourProvider
        components={null}
        steps={[]}
        afterOpen={disableBody}
        beforeClose={enableBody}
        styles={{
          badge: base => ({ ...base, background: "#00B6F1 !important"  }),
          popover: base => ({
            ...base,
            borderRadius: "18px",
          }),
          close: base => ({ ...base, right: 20,  top: 8 }),
          dot: base => ({
            ...base,
            background: "#00B6F1 !important" 
          }),
        }}
      >
        <App />
      </TourProvider>
    </React.Suspense>
  </React.StrictMode>,
  document.getElementById("root")
);
