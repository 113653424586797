import { IResourceComponentsProps } from "@pankod/refine-core";

import {
  List,
  Table,
  Tag,
  useTable,
  EditButton,
  Icons,
  FilterDropdown,
  Typography,
  useSelect,
  Select,
  useModalForm,
  Radio,
  Image,
} from "@pankod/refine-antd";

import { AssetsCreate, AssetsEdit } from "./";

import { Assets, Tags } from "interfaces";

const { Title } = Typography;

export const AssetsList: React.FC<IResourceComponentsProps> = () => {
  const { tableProps, sorter } = useTable<Assets.AssetItem>({
    initialSorter: [
      {
        field: "updatedAt",
        order: "desc",
      },
    ],
  });

  const {
    formProps: assetsCreateFormProps,
    modalProps: assetsCreateModalProps,
    show: userCreateModalShow,
  } = useModalForm({
    action: "create",
    submitOnEnter: true,
    successNotification: {
      message: "Başarılı",
      description: "Ürün başarıyla yaratıldı",
      type: "success",
    },
  });

  const {
    formProps: assetsEditFormProps,
    modalProps: assetsEditModalProps,
    show: assetsEditModalShow,
    queryResult: assetsEditQueryResult,
  } = useModalForm<Assets.AssetItem>({
    action: "edit",
    submitOnEnter: true,
    successNotification: {
      message: "Başarılı",
      description: "Ürün başarıyla güncellendi",
      type: "success",
    },
  });

  const { selectProps: tagsFilterSelectProps } = useSelect<Tags.TagItem>({
    fetchSize: 50000,
    resource: "tags",
    optionLabel: "name",
    optionValue: "id",
  });

  return (
    <>
      <List
        title=""
        createButtonProps={{
          type: "primary",
          size: "large",
          shape: "round",
          icon: <Icons.UserAddOutlined />,
          onClick: () => userCreateModalShow(),
        }}
      >
        <Table {...tableProps} scroll={{ x: 1 }} rowKey="id">
          <Table.Column<Assets.AssetItem>
            title="Görsel"
            dataIndex="path"
            render={(_, record) => (
              <Image src={record.path} alt={record.name} width={80} />
            )}
          />
          <Table.Column title="Ürün Adı" dataIndex="name" />

          <Table.Column
            key="tags.id"
            dataIndex="tags"
            title="Etiketler"
            render={(value) => {
              return (
                <>
                  {value.map((tag: Tags.TagItem) => {
                    return (
                      <Tag className="mb-1" key={tag.id}>
                        {tag.name}
                      </Tag>
                    );
                  })}
                </>
              );
            }}
            filterDropdown={(props) => {
              return (
                <FilterDropdown {...props}>
                  <Title level={5}>Aramak istediğiniz etiketi yazın</Title>
                  <Select
                    {...tagsFilterSelectProps}
                    style={{ width: "100%" }}
                    mode="multiple"
                  />
                </FilterDropdown>
              );
            }}
          />
          <Table.Column<Assets.AssetItem>
            title="Durum"
            dataIndex="isActive"
            render={(_, record) => {
              if (record.isActive) {
                return <Tag color="success">Aktif</Tag>;
              }

              return <Tag color="red">Pasif</Tag>;
            }}
            filterDropdown={(props) => {
              return (
                <FilterDropdown {...props}>
                  <Title level={5}>Durum</Title>
                  <Radio.Group
                    options={[
                      { label: "Aktif", value: "1" },
                      { label: "Pasif", value: "0" },
                    ]}
                  />
                </FilterDropdown>
              );
            }}
          />
          <Table.Column<Assets.AssetItem>
            title="Güncellenme Tarihi"
            dataIndex="updatedAt"
            sorter // Sıralama özelliğini ekledik
            render={(value) => new Date(value).toLocaleString("tr-TR")}
          />
          <Table.Column<Assets.AssetItem>
            title="İşlemler"
            dataIndex="actions"
            render={(_, record) => (
              <EditButton
                size="small"
                onClick={() => {
                  assetsEditModalShow(record.id);
                }}
              >
                Düzenle
              </EditButton>
            )}
          />
        </Table>
      </List>
      <AssetsCreate
        formProps={assetsCreateFormProps}
        modalProps={assetsCreateModalProps}
      />
      <AssetsEdit
        formProps={assetsEditFormProps}
        modalProps={assetsEditModalProps}
        queryResult={assetsEditQueryResult}
      />
    </>
  );
};
