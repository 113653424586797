import { IResourceComponentsProps, useCustom } from "@pankod/refine-core";
import {
  List,
  Table,
  useTable,
  EditButton,
  Icons,
  Skeleton,
  DeleteButton,
} from "@pankod/refine-antd";

import { Campaigns } from "interfaces/campaigns";
import ENV from "utils/env";
import dayjs from "dayjs";
import { CampaignTypes } from "./constants";

export const CampaignsList: React.FC<IResourceComponentsProps> = () => {
  const { tableProps } = useTable<Campaigns.CampaignItem>({});
  const { data: response, isFetching } = useCustom({
    url: `${ENV.API_URL}/api/campaign/`,
    method: "get",
  });
  if (isFetching) {
    return <Skeleton />;
  }
  const dataSource = response.data.map((item: any) => {
    let campaignType = CampaignTypes.filter((c) => c.key === item.campaignType);
    return {
      id: item.id,
      label: item.label,
      key: item.key,
      startdate: dayjs(item.startdate).format("DD-MM-YYYY HH:mm"),
      enddate: dayjs(item.enddate).format("DD-MM-YYYY HH:mm"),
      configs: item.configs,
      dbname: item.dbname,
      dbpassword: item.dbpassword,
      dbusername: item.dbusername,
      campaignType: campaignType ? campaignType[0].label : null,
      dbport: item.dbport,
      dbhost: item.dbhost,
    };
  });
  return (
    <>
      <List
        title="Kampanya Yönetimi"
        createButtonProps={{
          type: "primary",
          size: "large",
          shape: "round",
          icon: <Icons.PlusOutlined />,
          children: "Yeni Kampanya Yayınla",
        }}
      >
        <Table
          {...tableProps}
          scroll={{ x: 1 }}
          rowKey="id"
          dataSource={dataSource}
        >
          <Table.Column dataIndex="label" title="Etiket" />
          <Table.Column dataIndex="key" title="Anahtar" />
          <Table.Column dataIndex="campaignType" title="Kampanya Tipi" />
          <Table.Column dataIndex="startdate" title="Başlangıç Tarihi" />
          <Table.Column dataIndex="enddate" title="Bitiş Tarihi" />

          <Table.Column<Campaigns.CampaignItem>
            dataIndex="dbname"
            title="Veritabanı Adı"
          />
          <Table.Column<Campaigns.CampaignItem>
            dataIndex="dbusername"
            title="Veritabanı Kullanıcı Adı"
          />
          <Table.Column<Campaigns.CampaignItem>
            dataIndex="dbport"
            title="Veritabanı Portu"
          />
          <Table.Column<Campaigns.CampaignItem>
            dataIndex="dbhost"
            title="Veritabanı Host"
          />
          <Table.Column<Campaigns.CampaignItem>
            title="İşlemler"
            dataIndex="actions"
            render={(_, record) => (
              <>
                <EditButton
                  size="small"
                  icon={<Icons.EditOutlined />}
                  recordItemId={record.id}
                >
                  Düzenle
                </EditButton>
                <DeleteButton
                  size="small"
                  icon={<Icons.DeleteOutlined />}
                  recordItemId={record.id}
                >
                  Sil
                </DeleteButton>
              </>
            )}
          />
        </Table>
      </List>
    </>
  );
};
