import dayjs from "dayjs";

export const UpdatedAtDisplay = ({ response, message }) => {
  if (!response || !response.data) {
    return null;
  }

  const updatedAt = response.data.updatedAt;
  const parsedUpdatedAt = dayjs(updatedAt).format("DD/MM/YYYY HH:mm");

  return <p>{`${message} ${parsedUpdatedAt}`}</p>;
};
