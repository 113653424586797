import React from "react";
import {
  Button,
  Card,
  Col,
  DatePicker,
  Form,
  Input,
  InputNumber,
  Row,
  Typography,
  Space,
  useForm,
  Select,
} from "@pankod/refine-antd";
import { PlusOutlined, MinusCircleOutlined } from "@ant-design/icons";
import { IResourceComponentsProps } from "@pankod/refine-core";
import { Campaigns } from "interfaces/campaigns";
import { CampaignTypes } from "./constants";

export const CampaignCreate: React.FC<IResourceComponentsProps> = () => {
  const { formProps, saveButtonProps, form } = useForm<Campaigns.CampaignItem>({
    redirect: "list",
    successNotification: {
      message: "Başarılı",
      description: "Kampanya başarıyla oluşturuldu",
      type: "success",
    },
  });

  const renderContent = () => {
    return (
      <Row gutter={[16, 16]}>
        <Col span={12}>
          <Form.Item
            label="Etiket"
            name="label"
            rules={[{ required: true, message: "Lütfen etiket girin" }]}
          >
            <Input placeholder="Etiket" />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            label="Anahtar"
            name="key"
            rules={[{ required: true, message: "Lütfen anahtar girin" }]}
          >
            <Input placeholder="Anahtar" />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            label="Başlangıç Tarihi"
            name="startdate"
            rules={[
              { required: true, message: "Lütfen başlangıç tarihini girin" },
            ]}
          >
            <DatePicker placeholder="Başlangıç Tarihi" />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            label="Bitiş Tarihi"
            name="enddate"
            rules={[{ required: true, message: "Lütfen bitiş tarihini girin" }]}
          >
            <DatePicker placeholder="Bitiş Tarihi" />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            label="Veritabanı Adı"
            name="dbname"
            rules={[{ required: true, message: "Lütfen veritabanı adı girin" }]}
          >
            <Input placeholder="Veritabanı Adı" />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            label="Veritabanı Kullanıcı Adı"
            name="dbusername"
            rules={[
              {
                required: true,
                message: "Lütfen veritabanı kullanıcı adını girin",
              },
            ]}
          >
            <Input placeholder="Veritabanı Kullanıcı Adı" />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            label="Veritabanı Şifresi"
            name="dbpassword"
            rules={[
              { required: true, message: "Lütfen veritabanı şifresini girin" },
            ]}
          >
            <Input.Password placeholder="Veritabanı Şifresi" />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            label="Veritabanı Portu"
            name="dbport"
            rules={[
              { required: true, message: "Lütfen veritabanı portunu girin" },
            ]}
          >
            <InputNumber
              placeholder="Veritabanı Portu"
              style={{ width: "100%" }}
            />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            label="Veritabanı Host"
            name="dbhost"
            rules={[
              { required: true, message: "Lütfen veritabanı hostunu girin" },
            ]}
          >
            <Input placeholder="Veritabanı Host" />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            label="Kampanya Türü"
            name="campaignType"
            rules={[
              { required: true, message: "Lütfen kampanya türünü seçin" },
            ]}
          >
            <Select placeholder="Kampanya Türü">
              {CampaignTypes.map((campaign) => (
                <Select.Option value={campaign.key} key={campaign.key}>
                  {campaign.label}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </Col>

        {/* 
        <Col span={24}>
          <Form.List name="configs">
            {(fields, { add, remove }) => (
              <>
                {fields.map((field) => (
                  <Space
                    key={field.key}
                    style={{ display: "flex", marginBottom: 8 }}
                    align="baseline"
                  >
                    <Form.Item
                      {...field}
                      name={[field.name, "name"]}
                      rules={[
                        { required: true, message: "Lütfen bir anahtar girin" },
                      ]}
                    >
                      <Input placeholder="Anahtar" />
                    </Form.Item>
                    <Form.Item
                      {...field}
                      name={[field.name, "value"]}
                      rules={[
                        { required: true, message: "Lütfen bir değer girin" },
                      ]}
                    >
                      <Input placeholder="Değer" />
                    </Form.Item>
                    <MinusCircleOutlined onClick={() => remove(field.name)} />
                  </Space>
                ))}
                <Form.Item>
                  <Button
                    type="dashed"
                    onClick={() => add()}
                    block
                    icon={<PlusOutlined />}
                  >
                    Config Ekle
                  </Button>
                </Form.Item>
              </>
            )}
          </Form.List>
          
        </Col>
        Configs için Dinamik Form Listesi */}

        <Col span={24}>
          <Form.Item>
            <Button type="primary" {...saveButtonProps}>
              Kaydet
            </Button>
          </Form.Item>
        </Col>
      </Row>
    );
  };

  return (
    <Card
      style={{ marginBottom: 10 }}
      title={
        <Typography.Title level={3}>Yeni Kampanya Oluştur</Typography.Title>
      }
    >
      <Form {...formProps} form={form} layout="vertical">
        {renderContent()}
      </Form>
    </Card>
  );
};
