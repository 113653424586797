import { IResourceComponentsProps } from "@pankod/refine-core";
import React from "react";
import { Card, Row, Col, Button } from "@pankod/refine-antd";
import { AttendedUnattendedBranchCountPie } from "./charts/AttendedUnattendedBranchCountPie";
import { AttendedCountLine } from "./charts/AttendedCountLine";
import { TopFiveBranchList } from "./TopFiveBranchList";
import { AttendedCountBar } from "./charts/AttendedCountBar";
import { AttendCountsByCityList } from "./AttendCountsByCityList";
import { AttendedHoursCountBar } from "./charts/AttendedHoursCountBar";

interface OverviewProps extends IResourceComponentsProps {
  setActiveKey: (key: string) => void;
}

export const Overview: React.FC<OverviewProps> = ({ setActiveKey }) => {
  return (
    <Row gutter={[16, 16]}>
      <Col xs={24} sm={24} md={12} lg={12} xl={12}>
        <Card style={{ height: "100%" }}>
          <h3>Katılım Sağlanan ve Sağlanmayan Şube Grafiği</h3>
          <AttendedUnattendedBranchCountPie />
        </Card>
      </Col>
      <Col xs={24} sm={24} md={12} lg={12} xl={12}>
        <Card style={{ height: "100%" }}>
          <h3>Katılınılan Çekiliş/Gün Çizgi Grafiği</h3>
          <AttendedCountLine />
        </Card>
      </Col>
      <Col xs={24} sm={24} md={12} lg={12} xl={12}>
        <Card style={{ height: "100%" }}>
          <h3>En Çok Katılım Sağlanan 5 Şube</h3>
          <TopFiveBranchList />
          <div
            style={{
              position: "absolute",
              bottom: "16px",
              right: "16px",
              display: "flex",
              justifyContent: "flex-end",
            }}
          >
            <Button onClick={() => setActiveKey("3")}>Hepsini Gör/İndir</Button>
          </div>
        </Card>
      </Col>
      <Col xs={24} sm={24} md={12} lg={12} xl={12}>
        <Card style={{ height: "100%" }}>
          <h3>Günlere Göre Gruplanmış Katılım Sayısı</h3>
          <AttendedCountBar />
        </Card>
      </Col>
      <Col xs={24} sm={24} md={24} lg={24} xl={24}>
        <Card style={{ height: "100%" }}>
          <h3>En Çok ve En Az Katılım Sağlanan Şehirler</h3>
          <AttendCountsByCityList />

          <div
            style={{
              position: "absolute",
              bottom: "16px",
              right: "16px",
              display: "flex",
              justifyContent: "flex-end",
            }}
          >
            <Button onClick={() => setActiveKey("1")}>Hepsini Gör/İndir</Button>
          </div>
        </Card>
      </Col>
      <Col xs={24} sm={24} md={24} lg={24} xl={24}>
        <Card style={{ height: "100%" }}>
          <h3>Saatlere Göre Gruplanmış Katılım Sayısı</h3>
          <AttendedHoursCountBar />
        </Card>
      </Col>
    </Row>
  );
};
