import { useTour } from "@reactour/tour";
import { useCallback } from "react";

export type UseProductTourProps = {
    templateResourceSlug: string;
}

export const useProductTour =
    () => {
        const { setIsOpen, setSteps, setCurrentStep } = useTour();

        const startTour = useCallback(
            ({
                templateResourceSlug
            }: UseProductTourProps) => {
                const tourIsCompleted = localStorage.getItem(`tourIsCompleted-${templateResourceSlug}`) === "true";
                if (tourIsCompleted) {
                    return;
                }

                if (templateResourceSlug === "product-discount-price") {

                    setSteps([{
                        content: "Ürün ismindeki gereksiz bulduğunuz kelimelerin üstüne tıklayarak içerikten çıkarın. İsterseniz ürün ismini, kelimeleri kaydırarak düzenleyebilirsiniz.",
                        selector: `[data-tour="step-1"]`
                    },
                    {
                        content: "Bu alanda ürün görselinin boyutunu düzenleyebilirsiniz.",
                        selector: `[data-tour="step-2"]`
                    },
                    {
                        content: "Bu alanda fiyatın yazdığı patlangaçın konumunu düzenleyebilirsiniz.",
                        selector: `[data-tour="step-priceBounceHeight"]`
                    },
                    {
                        content: "Bu alanda taksit patlangaçının konumunu düzenleyebilirsiniz.",
                        selector: `[data-tour="step-installmentBounceHeight"]`
                    },
                    {
                        content: "Bu butonla içeriğiniz için ön izleme yaparak kontrol edebilirsiniz.",
                        selector: `[data-tour="step-5"]`
                    }]);
                } else if (templateResourceSlug === "product-discount-percent") {
                    setSteps([{
                        content: "Ürün ismindeki gereksiz bulduğunuz kelimelerin üstüne tıklayarak içerikten çıkarın. İsterseniz ürün ismini, kelimeleri kaydırarak düzenleyebilirsiniz.",
                        selector: `[data-tour="step-1"]`
                    },
                    {
                        content: "Bu alanda ürün görselinin boyutunu düzenleyebilirsiniz.",
                        selector: `[data-tour="step-2"]`
                    },
                    {
                        content: "Bu alanda yüzdenin yazdığı patlangaçın konumunu düzenleyebilirsiniz.",
                        selector: `[data-tour="step-percentBounceHeight"]`
                    },
                    {
                        content: "Bu alanda taksit patlangaçının konumunu düzenleyebilirsiniz.",
                        selector: `[data-tour="step-installmentBounceHeight"]`
                    },
                    {
                        content: "Bu butonla içeriğiniz için ön izleme yaparak kontrol edebilirsiniz.",
                        selector: `[data-tour="step-5"]`
                    }]);
                } else if (templateResourceSlug === "product-discount-gift") {
                    setSteps([{
                        content: "Ürün ismindeki gereksiz bulduğunuz kelimelerin üstüne tıklayarak içerikten çıkarın. İsterseniz ürün ismini, kelimeleri kaydırarak düzenleyebilirsiniz.",
                        selector: `[data-tour="step-1"]`
                    },
                    {
                        content: "Bu alanda ürün görselinin boyutunu düzenleyebilirsiniz.",
                        selector: `[data-tour="step-2"]`
                    },
                    {
                        content: "Bu alanda fiyatın yazdığı patlangaçın konumunu düzenleyebilirsiniz.",
                        selector: `[data-tour="step-priceBounceHeight"]`
                    },
                    {
                        content: "Bu alanda hediye patlangaçının konumunu düzenleyebilirsiniz.",
                        selector: `[data-tour="step-giftTextBounceHeight"]`
                    },
                    {
                        content: "Bu butonla içeriğiniz için ön izleme yaparak kontrol edebilirsiniz.",
                        selector: `[data-tour="step-5"]`
                    }]);
                }
                setCurrentStep(0);
                setIsOpen(true)
                localStorage.setItem(`tourIsCompleted-${templateResourceSlug}`, "true");

            },
            [],
        );

        return startTour;
    };
