import { IResourceComponentsProps } from "@pankod/refine-core";
import { Card, Tabs } from "@pankod/refine-antd";
import { DashboardSelector } from "components/DashboardSelector/dashboardselector";
import { useState } from "react";
import { useDashboard } from "dashboardProvider";
import { Overview } from "./overview";
import { AttendCountsBasedOnCities } from "./tables/AttendCountsBasedOnCities";
import { TableUnattendedBranchDetails } from "./tables/UnattendedBranchDetails";
import { TableMostAttendedBranchDetails } from "./tables/MostAttendedBranchDetails";
import { TableMostAttendedDays } from "./tables/MostAttendedDays";
import { TableMostAttendedHours } from "./tables/MostAttendedHours";
import { TableAttendedUsers } from "./tables/AttendedUsers";
import { useAuth } from "definitions";
import { RoleTypes } from "interfaces/enums";
import { TableDailyAttendedBranchesCount } from "./tables/DailyAttendedBranchesCounts";

export const LotteryDashboard: React.FC<IResourceComponentsProps> = () => {
  const [activeKey, setActiveKey] = useState<string>("0");
  const { selectedDashboard } = useDashboard();
  const isAuthorized = useAuth();

  const onChange = (key: string) => {
    setActiveKey(key);
  };

  const items = [
    {
      key: "0",
      label: "Genel Bakış",
      children: <Overview setActiveKey={setActiveKey} />,
    },
    {
      key: "1",
      label: "Şehirlere Göre Katılım Sayıları",
      children: <AttendCountsBasedOnCities />,
    },
    {
      key: "2",
      label: "Katılım Sağlanmamış Şubelerin Detayları",
      children: <TableUnattendedBranchDetails />,
    },
    {
      key: "3",
      label: "En Çok Katılım Sağlanan Şubelerin Detayları",
      children: <TableMostAttendedBranchDetails />,
    },

    {
      key: "4",
      label: "En Çok Katılım Sağlanan Günler",
      children: <TableMostAttendedDays />,
    },
    {
      key: "5",
      label: "En Çok Katılım Sağlanan Saatler",
      children: <TableMostAttendedHours />,
    },
    {
      key: "6",
      label: "Katılan Kullanıcıların Listesi",
      children: <TableAttendedUsers />,
    },
    {
      key: "7",
      label: "Bayilerin Günlük Katılım Listesi",
      children: <TableDailyAttendedBranchesCount />,
    },
  ];
  const campaignType = "lottery";

  return (
    <div>
      <DashboardSelector campaignType={campaignType}></DashboardSelector>
      {selectedDashboard && (
        <Card
          // loading={isFetching}
          style={{ marginBottom: 10 }}
          bodyStyle={{ minHeight: "500px" }}
        >
          <Tabs defaultActiveKey="0" activeKey={activeKey} onChange={onChange}>
            {items.map((item) => {
              if (item.key === "6" && !isAuthorized(RoleTypes.SUPER_ADMIN)) {
                return null;
              }
              return (
                <Tabs.TabPane tab={item.label} key={item.key}>
                  {item.children}
                </Tabs.TabPane>
              );
            })}
          </Tabs>
        </Card>
      )}
    </div>
  );
};
