import { IResourceComponentsProps } from "@pankod/refine-core";
import React from "react";
import { PlayedUnplayedBranchCountPie } from "./charts/PlayedUnplayedBranchCountPie";
import { PlayedGameCountLine } from "./charts/PlayedGameCountLine";
import { Card, Row, Col, Button } from "@pankod/refine-antd";
import { TopFiveBranchList } from "./TopFiveBranchList";
import { PlayedGameCountBar } from "./charts/PlayedGameCountBar";
import { PlayedGamesHoursCountBar } from "./charts/PlayedGamesHoursCountBar";
import { GameCountsByCityList } from "./GameCountsByCityList";

interface OverviewProps extends IResourceComponentsProps {
  setActiveKey: (key: string) => void;
}

export const Overview: React.FC<OverviewProps> = ({ setActiveKey }) => {
  return (
    <Row gutter={[16, 16]}>
      <Col xs={24} sm={24} md={12} lg={12} xl={12}>
        <Card style={{ height: "100%" }}>
          <h3>Oynanan ve Oynanmayan Şube Grafiği</h3>
          <PlayedUnplayedBranchCountPie />
        </Card>
      </Col>
      <Col xs={24} sm={24} md={12} lg={12} xl={12}>
        <Card style={{ height: "100%" }}>
          <h3>Oynanan Oyun/Gün Çizgi Grafiği</h3>
          <PlayedGameCountLine />
        </Card>
      </Col>
      <Col xs={24} sm={24} md={12} lg={12} xl={12}>
        <Card style={{ height: "100%" }}>
          <h3>En Çok Oyun Oynanan 5 Şube</h3>
          <TopFiveBranchList />
          <div
            style={{
              position: "absolute",
              bottom: "16px",
              right: "16px",
              display: "flex",
              justifyContent: "flex-end",
            }}
          >
            <Button onClick={() => setActiveKey("3")}>Hepsini Gör/İndir</Button>
          </div>
        </Card>
      </Col>
      <Col xs={24} sm={24} md={12} lg={12} xl={12}>
        <Card style={{ height: "100%" }}>
          <h3>Günlere Göre Gruplanmış Oyun Sayısı</h3>
          <PlayedGameCountBar />
        </Card>
      </Col>
      <Col xs={24} sm={24} md={24} lg={24} xl={24}>
        <Card style={{ height: "100%" }}>
          <h3>En Çok ve En Az Oyun Oynanan Şehirler</h3>
          <GameCountsByCityList />
          <div
            style={{
              position: "absolute",
              bottom: "16px",
              right: "16px",
              display: "flex",
              justifyContent: "flex-end",
            }}
          >
            <Button onClick={() => setActiveKey("1")}>Hepsini Gör/İndir</Button>
          </div>
        </Card>
      </Col>
      <Col xs={24} sm={24} md={24} lg={24} xl={24}>
        <Card style={{ height: "100%" }}>
          <h3>Saatlere Göre Gruplanmış Oyun Sayısı</h3>
          <PlayedGamesHoursCountBar />
        </Card>
      </Col>
    </Row>
  );
};
