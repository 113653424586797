import React, { useEffect } from "react";
import { IResourceComponentsProps } from "@pankod/refine-core";
import {
  Button,
  Card,
  DatePicker,
  Form,
  Input,
  InputNumber,
  Row,
  Col,
  Typography,
  Space,
  Select,
  useForm,
} from "@pankod/refine-antd";
import { MinusCircleOutlined, PlusOutlined } from "@ant-design/icons";
import dayjs from "dayjs";

import { CampaignTypes } from "./constants";
import { Campaigns } from "interfaces/campaigns";

export const CampaignEdit: React.FC<IResourceComponentsProps> = () => {
  const { formProps, saveButtonProps, form } = useForm<Campaigns.CampaignItem>({
    redirect: "list",
    successNotification: {
      message: "Başarılı",
      description: "Kampanya başarıyla güncellendi",
      type: "success",
    },
  });

  useEffect(() => {
    if (formProps.initialValues) {
      form.setFieldsValue({
        label: formProps.initialValues?.label,
        key: formProps.initialValues?.key,
        startdate: dayjs(formProps.initialValues?.startdate),
        enddate: dayjs(formProps.initialValues?.enddate),
        dbname: formProps.initialValues?.dbname,
        dbusername: formProps.initialValues?.dbusername,
        dbport: formProps.initialValues?.dbport,
        dbhost: formProps.initialValues?.dbhost,
        campaignType: formProps.initialValues?.campaignType,
      });
    }
  }, [formProps.initialValues, form]);

  const renderContent = () => {
    return (
      <Row gutter={[16, 16]}>
        <Col span={12}>
          <Form.Item
            label="Etiket"
            name="label"
            rules={[{ required: true, message: "Lütfen etiket girin" }]}
          >
            <Input placeholder="Etiket" />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            label="Anahtar"
            name="key"
            rules={[{ required: true, message: "Lütfen anahtar girin" }]}
          >
            <Input placeholder="Anahtar" />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            valuePropName="startdate"
            label="Başlangıç Tarihi"
            name="startdate"
            rules={[
              { required: true, message: "Lütfen başlangıç tarihini girin" },
            ]}
          >
            <DatePicker placeholder="Başlangıç Tarihi" />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            valuePropName="enddate"
            label="Bitiş Tarihi"
            name="enddate"
            rules={[{ required: true, message: "Lütfen bitiş tarihini girin" }]}
          >
            <DatePicker placeholder="Bitiş Tarihi" />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            label="Veritabanı Adı"
            name="dbname"
            rules={[{ required: true, message: "Lütfen veritabanı adı girin" }]}
          >
            <Input placeholder="Veritabanı Adı" />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            label="Veritabanı Kullanıcı Adı"
            name="dbusername"
            rules={[
              {
                required: true,
                message: "Lütfen veritabanı kullanıcı adını girin",
              },
            ]}
          >
            <Input placeholder="Veritabanı Kullanıcı Adı" />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item label="Veritabanı Şifresi" name="dbpassword">
            <Input.Password placeholder="Veritabanı Şifresi" />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            label="Veritabanı Portu"
            name="dbport"
            rules={[
              { required: true, message: "Lütfen veritabanı portunu girin" },
            ]}
          >
            <InputNumber
              placeholder="Veritabanı Portu"
              style={{ width: "100%" }}
            />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            label="Veritabanı Host"
            name="dbhost"
            rules={[
              { required: true, message: "Lütfen veritabanı hostunu girin" },
            ]}
          >
            <Input placeholder="Veritabanı Host" />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            label="Kampanya Türü"
            name="campaignType"
            rules={[
              { required: true, message: "Lütfen kampanya türünü seçin" },
            ]}
          >
            <Select placeholder="Kampanya Türü">
              {CampaignTypes.map((campaign) => (
                <Select.Option value={campaign.key} key={campaign.key}>
                  {campaign.label}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </Col>

        {/* Configs için Dinamik Form Listesi 
        <Col span={24}>
          <Form.List name="configs">
            {(fields, { add, remove }) => (
              <>
                {fields.map(({ key, name, ...restField }) => (
                  <div key={key}>
                    <Space
                      style={{ display: "flex", marginBottom: 8 }}
                      align="baseline"
                    >
                      <Form.Item
                        {...restField}
                        name={[name, "name"]}
                        rules={[{ required: true, message: "Missing Name" }]}
                      >
                        <Input placeholder="Name" />
                      </Form.Item>
                      <Form.Item
                        {...restField}
                        name={[name, "value"]}
                        rules={[{ required: true, message: "Missing Value" }]}
                      >
                        <InputNumber placeholder="Value" />
                      </Form.Item>
                      <MinusCircleOutlined onClick={() => remove(name)} />
                    </Space>
                  </div>
                ))}
                <Form.Item>
                  <Button
                    type="dashed"
                    onClick={() => add()}
                    block
                    icon={<PlusOutlined />}
                  >
                    Konfigürasyon Ekle
                  </Button>
                </Form.Item>
              </>
            )}
          </Form.List>
        </Col>
*/}
        <Col span={24}>
          <Form.Item>
            <Button type="primary" {...saveButtonProps}>
              Kaydet
            </Button>
          </Form.Item>
        </Col>
      </Row>
    );
  };

  return (
    <Card
      style={{ marginBottom: 10 }}
      title={<Typography.Title level={3}>Kampanyayı Düzenle</Typography.Title>}
    >
      {formProps.initialValues && (
        <Form {...formProps} layout="vertical">
          {renderContent()}
        </Form>
      )}
    </Card>
  );
};
