import { AuthProvider } from "@pankod/refine-core";
import axios from "axios";
import Cookies from "js-cookie";

import { Auth } from "interfaces";
import ENV from "utils/env";

export const authProvider: AuthProvider = {
  login: async ({ token, user }: Auth.UserResponse) => {
    axios.defaults.headers.common = {
      Authorization: `Bearer ${token}`,
    };

    const options: { path: string; expires?: number } = {
      path: "/",
      expires: 3 * 30 * 24 * 60 * 60,
    };

    Cookies.set("auth", JSON.stringify({ token, user }), options);
    return Promise.resolve();
  },
  logout: () => {
    Cookies.remove("auth");
    return Promise.resolve();
  },
  checkError: (error) => {
    if (error.statusCode === 401) {
      return Promise.reject();
    }
    return Promise.resolve();
  },
  checkAuth: async () => {
    const auth = Cookies.get("auth");
    if (!auth) {
      return Promise.reject();
    }

    const { token } = JSON.parse(auth);

    if (token) {
      try {
        await axios.get(`${ENV.API_URL}/api/auth/me`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        axios.defaults.headers.common = {
          Authorization: `Bearer ${token}`,
        };

        return Promise.resolve();
      } catch (error) {
        return Promise.reject();
      }
    }

    return Promise.reject();
  },
  getPermissions: () => {
    const auth = Cookies.get("auth");
    if (!auth) {
      return Promise.reject();
    }
    const { user } = JSON.parse(auth);
    return Promise.resolve(user.role);
  },
  getUserIdentity: async () => {
    const auth = Cookies.get("auth");
    if (!auth) {
      return Promise.reject();
    }
    const { user, token } = JSON.parse(auth);

    const me = await axios.get(`${ENV.API_URL}/api/auth/me`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    return Promise.resolve({ ...user, ...me.data });
  },
};

export { axios };
