import React, { useState } from "react";
import { LayoutProps } from "@pankod/refine-core";
import { AntdLayout, Grid } from "@pankod/refine-antd";

import { Header, Sider } from ".";

export const Layout: React.FC<LayoutProps> = ({
  children,
  OffLayoutArea,
}) => {
  const breakpoint = Grid.useBreakpoint();

  const [collapsed, setCollapsed] = useState<boolean>(false);

  return (
    <AntdLayout style={{ minHeight: "100vh", flexDirection: "row" }}>
      <Sider collapsed={collapsed} setCollapsed={setCollapsed} />
      <AntdLayout>
        {Header && <Header collapsed={collapsed} setCollapsed={setCollapsed} />}
        <AntdLayout.Content>
          <div
            style={{
              padding: breakpoint.sm ? 24 : 6,
              minHeight: 360,
            }}
          >{children}</div>
          {OffLayoutArea && <OffLayoutArea />}
        </AntdLayout.Content>
      </AntdLayout>
    </AntdLayout>
  );
};
