import { Column } from "@ant-design/charts";
import { Skeleton } from "@pankod/refine-antd";
import { IResourceComponentsProps, useCustom } from "@pankod/refine-core";
import { CitySelector } from "components/CitySelector/cityselector";
import dayjs from "dayjs";
import React from "react";
import ENV from "utils/env";
import { useDashboard } from "dashboardProvider";

export const PlayedGamesHoursCountBar: React.FC<
  IResourceComponentsProps
> = () => {
  const [selectedCity, setSelectedCity] = React.useState<string | null>(null);
  const { selectedDashboard } = useDashboard();
  const {
    data: response,
    isFetching,
    isError,
  } = useCustom({
    url: `${ENV.API_URL}/api/campaign/trivia/mostPlayedHours?dbkey=${selectedDashboard}`,
    method: "post",
    queryOptions: {
      retry: 0,
      enabled: true,
    },
    config: {
      payload: {
        city: selectedCity ? selectedCity : null,
      },
    },
  });

  if (isFetching) {
    return <Skeleton />;
  }

  if (isError) {
    return (
      <div>
        <CitySelector
          selectedCity={selectedCity}
          setSelectedCity={setSelectedCity}
        ></CitySelector>
        <div
          style={{ display: "flex", justifyContent: "center", marginTop: 16 }}
        >
          <span style={{ fontWeight: 800, fontSize: 20 }}>
            Bu filtrelere ait veri bulunamadı.
          </span>
        </div>
      </div>
    );
  }

  let data = response.data?.hours.map((i) => {
    return {
      key: i.hour,
      hour: i.hour,
      count: Number(i.game_count),
    };
  });

  data.sort(
    (a, b) => dayjs(a.hour, "HH:mm").hour() - dayjs(b.hour, "HH:mm").hour()
  );

  const config = {
    data,
    xField: "hour",
    yField: "count",
    label: {
      text: (originData) => {
        return "";
      },
      offset: 10,
    },
    legend: false,
  };

  return (
    <div>
      <CitySelector
        selectedCity={selectedCity}
        setSelectedCity={setSelectedCity}
      ></CitySelector>
      <Column {...config} />
    </div>
  );
};
