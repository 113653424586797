import React, { useState } from "react";
import {
  Form,
  Input,
  Select,
  useSelect,
  useRadioGroup,
  Radio,
  Modal,
  ModalProps,
  FormProps,
  RadioChangeEvent,
} from "@pankod/refine-antd";

import { Roles } from "interfaces";
import { RoleTypes } from "interfaces/enums";

type Props = {
  modalProps: ModalProps;
  formProps: FormProps;
};

export const UserCreate: React.FC<Props> = ({ formProps, modalProps }) => {
  const [selectedRoleType, setSelectedRoleType] = useState<
    RoleTypes | undefined
  >();

  const {
    radioGroupProps: rolesRadioGroupProps,
    queryResult: rolesQueryResult,
  } = useRadioGroup<Roles.RoleItem>({
    resource: "roles",
    optionLabel: "name",
    optionValue: "id",
  });

  const { selectProps: ommaSelectProps } = useSelect<any>({
    fetchSize: 5000,
    resource: "omma-shops",
    optionLabel: "name",
    optionValue: "id",
    filters: [{ field: "isActive", operator: "eq", value: true }],
  });

  const onChangeRole = (roleId: string) => {
    const role = rolesQueryResult.data.data.find(
      (item: Roles.RoleItem) => item.id === roleId
    );
    if (role) setSelectedRoleType(role.type);
  };

  const renderShopSelect = () => {
    if (!selectedRoleType) {
      return null;
    }

    const viewRoleTypes = [RoleTypes.SALES_MANAGER, RoleTypes.SHOP_MANAGER];
    if (viewRoleTypes.includes(selectedRoleType)) {
      return (
        <Form.Item
          label="Bağlı Mağazalar"
          name="ommaShops"
          rules={[
            {
              required: true,
              message: "Bağlı mağaza seçiniz.",
            },
          ]}
          getValueProps={(ommaShops?: { id: string }[]) => {
            return { value: ommaShops?.map((store) => store.id) };
          }}
          getValueFromEvent={(args: string[]) => {
            return args.map((item) => ({
              id: item,
            }));
          }}
        >
          <Select {...ommaSelectProps} mode="multiple" />
        </Form.Item>
      );
    }

    return null;
  };

  return (
    <Modal {...modalProps} title="Kullanıcı Ekle">
      <Form
        {...formProps}
        layout="vertical"
        initialValues={{
          isActive: true,
        }}
      >
        <Form.Item
          name={["role", "id"]}
          label="Kullanıcı Rolü"
          rules={[
            {
              required: true,
              message: "Kullanıcı rolünü seçiniz.",
            },
          ]}
        >
          <Radio.Group
            {...rolesRadioGroupProps}
            onChange={(e: RadioChangeEvent) => {
              onChangeRole(e.target.value);
            }}
          />
        </Form.Item>
        <Form.Item
          label="Kullanıcı Durumu"
          name="isActive"
          rules={[
            {
              required: true,
              message: "Kullanıcı durumunu seçiniz.",
            },
          ]}
        >
          <Radio.Group
            options={[
              {
                label: "Aktif",
                value: true,
              },
              {
                label: "Pasif",
                value: false,
              },
            ]}
          />
        </Form.Item>
        <Form.Item
          label="Adı"
          name="firstName"
          validateTrigger="onBlur"
          rules={[
            {
              required: true,
              message: "Adı alanı boş olamaz.",
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="Soyadı"
          name="lastName"
          validateTrigger="onBlur"
          rules={[
            {
              required: true,
              message: "Soyadı alanı boş olamaz.",
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="Eposta Adresi"
          name="email"
          validateTrigger="onBlur"
          rules={[
            {
              required: true,
              message: "Eposta alanı boş olamaz.",
            },
            {
              type: "email",
              message: "Hatalı eposta adresi",
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="GSM No"
          name="gsm"
          validateTrigger="onBlur"
          rules={[
            {
              required: true,
              message: "Gsm alanı boş olamaz.",
            },

            {
              len: 10,
              message: "Gsm numarası başında 0 olmadan 10 karakter olmalıdır.",
            },
          ]}
        >
          <Input type="number" />
        </Form.Item>

        {renderShopSelect()}
      </Form>
    </Modal>
  );
};
