import { Skeleton, Table } from "@pankod/refine-antd";
import { IResourceComponentsProps, useCustom } from "@pankod/refine-core";

import ENV from "utils/env";

export const AppInformationList: React.FC<IResourceComponentsProps> = () => {
  const { data: response, isFetching } = useCustom({
    url: `${ENV.API_URL}/api/campaign/info`,
    method: "get",
  });

  if (isFetching) {
    return <Skeleton />;
  }

  if (response?.data?.length < 1) {
    return (
      <span style={{ fontWeight: 800, fontSize: 20 }}>
        Kampanya bulunmamaktadır.
      </span>
    );
  }

  const dataSource = response.data.map((item: any) => ({
    key: item.initialTransactionId,
    label: item.label,
    initialTransactionId: item.initialTransactionId,
    latestTransactionId: item.latestTransactionId,
  }));

  const columns = [
    {
      title: "Kampanya Adı",
      dataIndex: "label",
      key: "label",
      width: "25%",
    },
    {
      title: "Başlangıç Tx Id",
      dataIndex: "initialTransactionId",
      key: "initialTransactionId",
      width: "25%",
    },
    {
      title: "En Son Yapılan Tx Id",
      dataIndex: "latestTransactionId",
      key: "latestTransactionId",
    },
  ];

  return (
    <div>
      <Table
        dataSource={dataSource}
        columns={columns}
        pagination={{ pageSize: 25, disabled: false }}
        scroll={{ x: 1500 }}
      />
    </div>
  );
};
