import { IResourceComponentsProps } from "@pankod/refine-core";
import { Card, Tabs, Typography } from "@pankod/refine-antd";
import { TablePlayCountsBasedOnCities } from "./tables/PlayCountsBasedOnCities";
import { TableUnplayedBrachDetails } from "./tables/UnplayedBrachDetails";
import { TableMostPlayedBranchDetails } from "./tables/MostPlayedBranchDetails";
import { TableMostPlayedDays } from "./tables/MostPlayedDays";
import { TableTopUsers } from "./tables/TopUsers";
import { Overview } from "./overview";
import { TableMostPlayedHours } from "./tables/MostPlayedHours";
import { DashboardSelector } from "components/DashboardSelector/dashboardselector";
import { useState } from "react";
import { useDashboard } from "dashboardProvider";
import { useAuth } from "definitions";
import { RoleTypes } from "interfaces/enums";

export const TriviaDashboard: React.FC<IResourceComponentsProps> = () => {
  const [activeKey, setActiveKey] = useState<string>("0");
  const { selectedDashboard } = useDashboard();
  const isAuthorized = useAuth();

  const onChange = (key: string) => {
    setActiveKey(key);
  };

  const items = [
    {
      key: "0",
      label: "Genel Bakış",
      children: <Overview setActiveKey={setActiveKey} />,
    },
    {
      key: "1",
      label: "Şehirlere Göre Katılım Sayıları",
      children: <TablePlayCountsBasedOnCities />,
    },
    {
      key: "2",
      label: "Oynanmamış Şubelerin Detayları",
      children: <TableUnplayedBrachDetails />,
    },
    {
      key: "3",
      label: "En Çok Oyun Oynanan Şubelerin Detayları",
      children: <TableMostPlayedBranchDetails />,
    },
    {
      key: "4",
      label: "En Çok Oyun Oynanan Günler",
      children: <TableMostPlayedDays />,
    },
    {
      key: "5",
      label: "En Çok Oyun Oynanan Saatler",
      children: <TableMostPlayedHours />,
    },
    {
      key: "6",
      label: "Kullanıcı Puan Sıralaması",
      children: <TableTopUsers />,
    },
  ];
  const campaignType = "trivia";
  return (
    <div>
      <DashboardSelector campaignType={campaignType}></DashboardSelector>
      {selectedDashboard && (
        <Card
          // loading={isFetching}
          style={{ marginBottom: 10 }}
          bodyStyle={{ minHeight: "500px" }}
        >
          <Tabs defaultActiveKey="0" activeKey={activeKey} onChange={onChange}>
            {items.map((item) => {
              if (item.key === "6" && !isAuthorized(RoleTypes.SUPER_ADMIN)) {
                return null;
              }
              return (
                <Tabs.TabPane tab={item.label} key={item.key}>
                  {item.children}
                </Tabs.TabPane>
              );
            })}
          </Tabs>
        </Card>
      )}
    </div>
  );
};
