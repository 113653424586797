import React, { useMemo, useCallback } from "react";

import { SelectAsset, Asset, AssetNamePiece } from "./IAsset";

const findPiecesAddChecked = (name: string) =>
    name.split(" ").map((piece) => ({
        value: piece,
        checked: true,
    }));

export const useAsset = (value: SelectAsset | undefined) => {
    const [activeAsset, setActiveAsset] = React.useState<Asset | undefined>(
        () =>
            value && {
                asset: value,
                assetNamePieces: value && findPiecesAddChecked(value.name),
            }
    );

    const setNewAsset = useCallback(
        (asset: SelectAsset) => {
            setActiveAsset({
                asset,
                assetNamePieces: asset && findPiecesAddChecked(asset.name),
            });
        },
        [activeAsset]
    );

    const toggleAssetNamePiece = useCallback(
        (index: number, checked: boolean) => {
            setActiveAsset((prev) => {
                const newSelectedNamePieces = prev?.assetNamePieces?.slice();
                if (newSelectedNamePieces)
                    newSelectedNamePieces[index].checked = checked;
                return {
                    asset: prev?.asset,
                    assetNamePieces: newSelectedNamePieces,
                };
            });
        },
        [activeAsset]
    );

    const setAssetNamePieces = (pieces: AssetNamePiece[]) =>
        setActiveAsset((prev) => ({
            asset: prev?.asset,
            assetNamePieces: pieces,
        }));

    const activeAssetName = useMemo(
        () =>
            activeAsset?.assetNamePieces
                ?.filter((el) => el.checked === true)
                .map((piece) => piece.value)
                .join(" "),
        [activeAsset]
    );

    return useMemo(
        () => ({
            activeAsset,
            setActiveAsset,
            activeAssetName,
            setNewAsset,
            toggleAssetNamePiece,
            setAssetNamePieces,
        }),
        [activeAsset]
    );
};
