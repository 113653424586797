// DashboardContext.tsx
import React, { createContext, useState, useContext, ReactNode } from "react";

interface DashboardContextType {
  selectedDashboard: string;
  setSelectedDashboard: React.Dispatch<React.SetStateAction<string>>;
  selectedOption: string | null;
  setSelectedOption: React.Dispatch<React.SetStateAction<string>>;
  campaignType: string | null;
  setCampaignType: React.Dispatch<React.SetStateAction<string>>;
}

const DashboardContext = createContext<DashboardContextType | undefined>(
  undefined
);

interface DashboardProviderProps {
  children: ReactNode;
}

export const DashboardProvider: React.FC<DashboardProviderProps> = ({
  children,
}) => {
  const [selectedDashboard, setSelectedDashboard] = useState<string | null>(
    null
  );
  const [selectedOption, setSelectedOption] = useState<string | null>(null);
  const [campaignType, setCampaignType] = useState<string | null>(null);

  return (
    <DashboardContext.Provider
      value={{
        selectedDashboard,
        setSelectedDashboard,
        selectedOption,
        setSelectedOption,
        campaignType,
        setCampaignType,
      }}
    >
      {children}
    </DashboardContext.Provider>
  );
};

export const useDashboard = () => {
  const context = useContext(DashboardContext);
  if (context === undefined) {
    throw new Error("useDashboard must be used within a DashboardProvider");
  }
  return context;
};
