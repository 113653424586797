import React from "react";
import { useDashboard } from "dashboardProvider";
import { IResourceComponentsProps } from "@pankod/refine-core";
import { Select, Skeleton, Typography } from "@pankod/refine-antd";
import { useCustom } from "@pankod/refine-core";
import ENV from "utils/env";

const { Option } = Select;

export const DashboardSelector = ({ campaignType }) => {
  const {
    setSelectedDashboard,
    setSelectedOption,
    selectedOption,
    setCampaignType,
  } = useDashboard();

  const { data: response, isFetching } = useCustom({
    url: `${ENV.API_URL}/api/campaign/`,
    method: "get",
  });

  if (isFetching) {
    return <Skeleton />;
  }

  let menuItems =
    response?.data
      ?.map((item: any) => ({
        key: item.key,
        label: item.label,
        campaignType: item.campaignType,
      }))
      .filter((item) => item.campaignType === campaignType) || [];

  const handleChange = (value: string) => {
    const selectedItem = menuItems.find((item) => item.label === value);

    if (selectedItem) {
      setSelectedDashboard(selectedItem.key);
      setSelectedOption(selectedItem.label);
      setCampaignType(selectedItem.campaignType);
    }
  };

  return (
    <div>
      {menuItems.length > 0 ? (
        <div>
          <Select
            placeholder={
              campaignType === "lottery"
                ? "Lütfen Görmek İstediğiniz Çekilişi Seçiniz "
                : "Lütfen Görmek İstediğiniz Yarışmayı Seçiniz"
            }
            style={{ width: "auto" }}
            onChange={handleChange}
            defaultValue={selectedOption}
          >
            {menuItems.map((item) => (
              <Option key={item.key} value={item.label}>
                {item.label}
              </Option>
            ))}
          </Select>
          {!selectedOption && (
            <Typography.Text
              style={{
                display: "block",
                textAlign: "center",
                width: "100%",
                fontWeight: "bold",
                fontSize: "18px",
              }}
            >
              {campaignType === "lottery"
                ? "Lütfen Görmek İstediğiniz Çekilişi Seçiniz"
                : "Lütfen Görmek İstediğiniz Yarışmayı Seçiniz"}
            </Typography.Text>
          )}
        </div>
      ) : (
        <Typography.Text
          style={{
            display: "block",
            textAlign: "center",
            width: "100%",
            fontWeight: "bold",
            fontSize: "18px",
          }}
        >
          {campaignType === "lottery"
            ? "Herhangi bir çekiliş bulunmamaktadır."
            : "Herhangi bir yarışma bulunmamaktadır."}
        </Typography.Text>
      )}
    </div>
  );
};
