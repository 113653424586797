import React from "react";
import {
  IResourceComponentsProps,
  useGetIdentity,
  useOne,
  useCustom,
  HttpError,
} from "@pankod/refine-core";

import {
  Affix,
  Button,
  Card,
  DatePicker,
  Form,
  Input,
  InputNumber,
  Select,
  Skeleton,
  useForm,
  useSelect,
  Row,
  Col,
  Typography,
  Alert,
  Space,
} from "@pankod/refine-antd";

import { FieldData } from "rc-field-form/es/interface";
import dayjs from "dayjs";

import { Contents, Roles, Shops, Templates } from "interfaces";
import { filterParametersFields, getBounceAreaFields } from "definitions";
import { ContentStatus, RoleTypes } from "interfaces/enums";
import { ContentPreview } from "components/contentpreview";
import { FormBuilder } from "components/formbuilder";
import { useEffect } from "react";
import ENV from "utils/env";

export const ContentEdit: React.FC<IResourceComponentsProps> = () => {
  const { formProps, saveButtonProps, form, queryResult } =
    useForm<Contents.ContentItem>({
      redirect: false,
      successNotification: {
        message: "Başarılı",
        description: "İçerik başarıyla güncellendi",
        type: "success",
      },
    });
  const [selectedShopId, setSelectedShopId] = React.useState<
    string | undefined
  >();

  const { data: contentsRetrieveOne } = queryResult;
  const [previewTemplateParams, setPreviewTemplateParams] =
    React.useState<Contents.ContentParameters[]>();
  const notEditableContentStatus = [
    ContentStatus.WAITING_APPROVAL,
    ContentStatus.PUBLISHED,
    ContentStatus.STOPPED,
  ];

  const canStopContentStatus = [
    ContentStatus.PUBLISHED,
    ContentStatus.WAITING_APPROVAL,
  ];

  const { data: templatesRetrieveOne, isFetched: templateIsFetched } =
    useOne<Templates.TemplateItem>({
      resource: "templates",
      id: queryResult.data?.data.template.id,
      queryOptions: {
        enabled: !!queryResult.data?.data.template.id,
      },
    });

  const { data: user, isFetched: userIsFetched } = useGetIdentity<{
    fullName: string;
    gsm: string;
    email: string;
    role: Roles.RoleItem;
    shops: [{ name: string; code: string; id: string; cityName: string }];
  }>();

  const { selectProps: shopsSelectProps } = useSelect<any>({
    fetchSize: 5000,
    resource: "omma-shops",
    optionLabel: "name",
    optionValue: "id",
    defaultValue: queryResult.data?.data.shop.id,
  });

  const { data: selectedShop } = useOne<any>({
    resource: "omma-shops",
    id: selectedShopId,
    queryOptions: {
      enabled: selectedShopId !== undefined,
    },
  });

  const { refetch: stopContent, isFetching: stopContentIsFetching } = useCustom<
    unknown,
    HttpError,
    unknown,
    { status: ContentStatus }
  >({
    url: `${ENV.API_URL}/api/contents/status/${queryResult?.data?.data.id}`,
    method: "patch",
    config: {
      payload: {
        status: ContentStatus.STOPPED,
      },
    },
    queryOptions: {
      retry: false,
      enabled: false,
      onSuccess: () => {
        queryResult.refetch();
      },
    },
    successNotification: {
      message: "Başarılı",
      description: "İçerik başarıyla güncellendi",
      type: "success",
    },
  });

  useEffect(() => {
    if (contentsRetrieveOne?.data) {
      const contentData = contentsRetrieveOne.data;
      const templateParams = contentsRetrieveOne.data.template.parameters;
      const contentDataParameters =
        previewTemplateParams || contentData.parameters;

      const contentParamsFormValues: FieldData[] = [];
      templateParams.map((templateParam) => {
        const contentParam: any = contentDataParameters.find(
          (param: any) => param.name === templateParam.name
        );
        if (contentParam) {
          contentParamsFormValues.push({
            name: `parameters.${templateParam.name}`,
            value: contentParam.value,
          });
        }

        return false;
      });

      contentParamsFormValues.push({
        name: "date",
        value: [dayjs(contentData.startDate), dayjs(contentData.expireDate)],
      });

      setSelectedShopId(contentsRetrieveOne.data.shop.id);

      form.setFields(contentParamsFormValues);
    }
  }, [queryResult.dataUpdatedAt]);

  const renderContent = () => {
    if (!templateIsFetched || !userIsFetched) {
      return <Skeleton active />;
    }

    if (contentsRetrieveOne.data) {
      const templateParams = contentsRetrieveOne.data.template.parameters;
      const contentData = contentsRetrieveOne.data;
      const contentDataParameters =
        previewTemplateParams || contentData.parameters;

      const authShopManager = user && user.role.type === RoleTypes.SHOP_MANAGER;
      if (authShopManager) {
        form.setFields([
          {
            name: ["shop", "id"],
            value: user!.shops[0].id,
          },
        ]);
      }

      const contentParams = previewTemplateParams || contentDataParameters;

      const { resourceSlug } = templatesRetrieveOne.data;

      return (
        <Row gutter={[16, 16]}>
          <Col md={10} xl={6}>
            <Affix offsetTop={20}>
              <ContentPreview
                parameters={[
                  ...contentParams,
                  {
                    name: "shopCityName",
                    value: authShopManager
                      ? user!.shops[0].cityName
                      : selectedShop?.data.cityName,
                  },
                ]}
                template={templatesRetrieveOne.data}
              />
            </Affix>
          </Col>

          <Col md={12} xl={18}>
            {notEditableContentStatus.includes(
              contentsRetrieveOne.data.status
            ) && (
              <Alert
                style={{ marginBottom: 12 }}
                type="error"
                message="Onay bekleyen, Durdurulan ve Yayında olan içerikler düzenlenemez!"
              />
            )}
            <Form
              {...formProps}
              layout="vertical"
              onFinish={(values: any) => {
                const parameters = filterParametersFields(
                  values,
                  /parameters.*/g
                );

                const startDate = dayjs(values.date[0]).startOf("day").toDate();
                const expireDate = dayjs(values.date[1]).endOf("day").toDate();

                return (
                  formProps.onFinish &&
                  formProps.onFinish({
                    name: values.name,
                    parameters: [
                      ...parameters,
                      {
                        name: "shopCityName",
                        value: authShopManager
                          ? user!.shops[0].cityName
                          : selectedShop?.data.cityName,
                      },
                    ],
                    template: {
                      id: templatesRetrieveOne.data.id,
                    },
                    shop: {
                      id: values.shop.id,
                    },
                    startDate,
                    expireDate,
                    weight: values.weight,
                  })
                );
              }}
            >
              <Card title="Kampanya Bilgileri" style={{ marginBottom: 9 }}>
                <Row gutter={[16, 0]}>
                  <Col lg={12}>
                    <Form.Item
                      label="Kampanya Adı"
                      name="name"
                      validateTrigger="onBlur"
                      rules={[
                        {
                          required: true,
                          message: "Kampanya Adı boş olamaz.",
                        },
                      ]}
                    >
                      <Input />
                    </Form.Item>
                  </Col>
                  <Col lg={6}>
                    <Form.Item
                      label="Gösterilme Ağırlığı"
                      name="weight"
                      validateTrigger="onBlur"
                      initialValue={50}
                      rules={[
                        {
                          required: true,
                          message: "Gösterilme ağırlığı boş olamaz.",
                        },
                        {
                          type: "number",
                          min: 1,
                          max: 100,
                          message:
                            "Gösterilme ağırlığı için 1-100 arasında bir değer girilmelidir.",
                        },
                      ]}
                    >
                      <InputNumber />
                    </Form.Item>
                  </Col>

                  <Col lg={12}>
                    <Form.Item
                      label="Kampanya Süresi"
                      name="date"
                      validateTrigger="onBlur"
                      rules={[
                        {
                          required: true,
                          message: "Başlangıç / Bitiş tarihini seçiniz.",
                        },
                      ]}
                    >
                      <DatePicker.RangePicker
                        disabledDate={(current) => {
                          return current && current < dayjs().startOf("day");
                        }}
                        style={{ width: "100%" }}
                        onChange={() => {
                          const params = filterParametersFields(
                            form.getFieldsValue()
                          );
                          setPreviewTemplateParams(params);
                        }}
                        format="DD/MM/YYYY"
                        placeholder={["Başlangıç Tarihi", "Bitiş Tarihi"]}
                      />
                    </Form.Item>
                  </Col>
                </Row>
              </Card>
              <Card
                title="Mağaza"
                hidden={authShopManager}
                style={{ marginBottom: 9 }}
              >
                <Row>
                  <Col lg={12}>
                    <Form.Item
                      label="Mağaza"
                      name={["shop", "id"]}
                      validateTrigger="onBlur"
                      rules={[
                        {
                          required: true,
                          message: "Mağaza seçiniz.",
                        },
                      ]}
                    >
                      <Select
                        {...shopsSelectProps}
                        onChange={(value) => {
                          setSelectedShopId(value as any as string);
                        }}
                      />
                    </Form.Item>
                  </Col>
                </Row>
              </Card>

              {![
                "product-discount-gift",
                "hr",
                "campaign",
                "double-campaign",
                "sim-1",
                "sim-2",
                "bill-payment-point",
              ].includes(resourceSlug) && (
                <Card title="Başlık" style={{ marginBottom: 9 }}>
                  <Row>
                    <Col lg={12}>
                      <FormBuilder
                        filterFields={["title"]}
                        triggerChange={() => {
                          const params = filterParametersFields(
                            form.getFieldsValue()
                          );
                          setPreviewTemplateParams(params);
                        }}
                        parameters={templateParams}
                      />
                    </Col>
                  </Row>
                </Card>
              )}

              {![
                "hr",
                "campaign",
                "double-campaign",
                "sim-1",
                "sim-2",
                "bill-payment-point",
              ].includes(resourceSlug) && (
                <Card title="Ürün" style={{ marginBottom: 9 }}>
                  <Col lg={18}>
                    <FormBuilder
                      filterFields={["product"]}
                      triggerChange={() => {
                        const params = filterParametersFields(
                          form.getFieldsValue()
                        );
                        setPreviewTemplateParams(params);
                      }}
                      parameters={templateParams}
                    />
                  </Col>
                  {resourceSlug === "product-discount-gift" && (
                    <>
                      <Col lg={18}>
                        <FormBuilder
                          filterFields={["gift"]}
                          triggerChange={() => {
                            const params = filterParametersFields(
                              form.getFieldsValue()
                            );
                            setPreviewTemplateParams(params);
                          }}
                          parameters={templateParams}
                        />
                      </Col>
                      <Col lg={8}>
                        {form.isFieldTouched("parameters.gift") && (
                          <FormBuilder
                            filterFields={["giftBounceHeight"]}
                            triggerChange={() => {
                              const params = filterParametersFields(
                                form.getFieldsValue()
                              );
                              setPreviewTemplateParams(params);
                            }}
                            parameters={templateParams}
                          />
                        )}
                      </Col>
                    </>
                  )}
                </Card>
              )}

              {[
                "product-discount-percent",
                "product-discount-price",
                "product-discount-gift",
                "campaign",
              ].includes(resourceSlug) && (
                <Card title="Patlangaç" style={{ marginBottom: 9 }}>
                  {getBounceAreaFields(resourceSlug).map((item) => (
                    <Row gutter={[16, 16]}>
                      {item.map((formItem) => (
                        <Col lg={formItem.lg}>
                          <FormBuilder
                            filterFields={[formItem.fieldName]}
                            triggerChange={() => {
                              const params = filterParametersFields(
                                form.getFieldsValue()
                              );
                              setPreviewTemplateParams(params);
                            }}
                            parameters={templateParams}
                          />
                        </Col>
                      ))}
                    </Row>
                  ))}
                </Card>
              )}

              {"double-campaign" === resourceSlug && (
                <Card title="Patlangaç" style={{ marginBottom: 9 }}>
                  <Typography.Title level={5}>1. Teklif</Typography.Title>
                  {getBounceAreaFields(`${resourceSlug}-1`).map((item) => (
                    <Row gutter={[16, 16]}>
                      {item.map((formItem) => (
                        <Col lg={formItem.lg}>
                          <FormBuilder
                            filterFields={[formItem.fieldName]}
                            triggerChange={() => {
                              const params = filterParametersFields(
                                form.getFieldsValue()
                              );
                              setPreviewTemplateParams(params);
                            }}
                            parameters={templateParams}
                          />
                        </Col>
                      ))}
                    </Row>
                  ))}

                  <Typography.Title level={5}>2. Teklif</Typography.Title>
                  {getBounceAreaFields(`${resourceSlug}-2`).map((item) => (
                    <Row gutter={[16, 16]}>
                      {item.map((formItem) => (
                        <Col lg={formItem.lg}>
                          <FormBuilder
                            filterFields={[formItem.fieldName]}
                            triggerChange={() => {
                              const params = filterParametersFields(
                                form.getFieldsValue()
                              );
                              setPreviewTemplateParams(params);
                            }}
                            parameters={templateParams}
                          />
                        </Col>
                      ))}
                    </Row>
                  ))}
                </Card>
              )}

              {"bill-payment-point" === resourceSlug && (
                <Card title="Fatura Tipleri" style={{ marginBottom: 9 }}>
                  <Row>
                    <Col lg={12}>
                      <FormBuilder
                        filterFields={["billType"]}
                        triggerChange={() => {
                          const params = filterParametersFields(
                            form.getFieldsValue()
                          );
                          setPreviewTemplateParams(params);
                        }}
                        parameters={templateParams}
                        tooltip="Bu alanda fatura tiplerinizi belirleyebilirsiniz."
                      />
                    </Col>
                  </Row>
                </Card>
              )}

              <Form.Item style={{ marginTop: 12 }}>
                <Space style={{ float: "right" }}>
                  {canStopContentStatus.includes(
                    contentsRetrieveOne.data.status
                  ) && (
                    <Button
                      size="large"
                      danger
                      shape="round"
                      onClick={() => stopContent()}
                      loading={stopContentIsFetching}
                    >
                      Yayını Durdur
                    </Button>
                  )}
                  <Button
                    type="primary"
                    shape="round"
                    size="large"
                    {...saveButtonProps}
                    disabled={notEditableContentStatus.includes(
                      contentsRetrieveOne.data.status
                    )}
                    style={{ float: "right" }}
                  >
                    Bilgileri Güncelle
                  </Button>
                </Space>
              </Form.Item>
            </Form>
          </Col>
        </Row>
      );
    }

    return null;
  };

  return (
    <Card
      style={{ marginBottom: 10 }}
      title={
        <Typography.Title level={3}>İçeriğinizi Düzenleyin</Typography.Title>
      }
    >
      {renderContent()}
    </Card>
  );
};
