import React, { useCallback, useEffect, useState } from "react";
import { file2Base64, GetOneResponse } from "@pankod/refine-core";
import {
  Form,
  Input,
  Select,
  useSelect,
  Radio,
  Modal,
  ModalProps,
  FormProps,
  Upload,
  Row,
  Col,
  Icons,
  getValueFromEvent,
  Divider,
  Button,
  Alert,
  Tooltip,
} from "@pankod/refine-antd";
import { QueryObserverResult } from "react-query";
import { Tags, Assets } from "interfaces";

type Props = {
  modalProps: ModalProps;
  formProps: FormProps;
  queryResult: QueryObserverResult<GetOneResponse<Assets.AssetItem>, unknown>;
};

export const AssetsEdit: React.FC<Props> = ({
  formProps,
  modalProps,
  queryResult,
}) => {
  const [base64Image, setBase64Image] = useState<string | undefined>();

  const [features, setFeatures] = useState<any>(null);

  const [updatedFeatures, setUpdatedFeatures] = useState<any>(
    queryResult.data?.data.updatedFeatures?.features
  );

  const [currentFeatures, setCurrentFeatures] = useState<any>(
    queryResult.data?.data.features?.features
  );
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [newFeature, setNewFeature] = useState({ name: "", value: "" });

  const [alertVisible, setAlertVisible] = useState(false);

  const renderDraggerContent = () => {
    if (base64Image) {
      return <img src={base64Image} width="100%" alt="" />;
    }

    if (queryResult.data?.data.path) {
      return <img src={queryResult.data?.data.path} width="100%" alt="" />;
    }

    return (
      <div style={{ marginBottom: "112px", marginTop: "112px" }}>
        <p className="ant-upload-drag-icon">
          <Icons.InboxOutlined />
        </p>
        <p className="ant-upload-text">
          Yüklemek için dosyayı bu alana tıklayın veya sürükleyin
        </p>
      </div>
    );
  };

  const { selectProps: tagsFilterSelectProps } = useSelect<Tags.TagItem>({
    fetchSize: 50000,
    resource: "tags",
    optionLabel: "name",
    optionValue: "id",
    defaultValue: queryResult.data?.data.tags.map((item) => item.id),
  });

  const onChange = async ({ fileList: newFileList }) => {
    const base64String = await file2Base64(newFileList[0]);
    setBase64Image(base64String);
  };

  const resetAllStates = () => {
    setBase64Image(undefined);
    setIsModalVisible(false);
    setNewFeature({ name: "", value: "" });
    setAlertVisible(false);
    resetForm();
    formProps.form?.resetFields();
  };

  // Modal kapandığında tüm state'leri sıfırla
  const handleModalClose = (e: React.MouseEvent<HTMLElement>) => {
    resetAllStates();
    modalProps.onCancel?.(e);
  };

  //--Remove feature from product
  const handleRemoveFeature = (code: string) => {
    const isFeatureExist = updatedFeatures?.find((f) => f.code === code);
    if (isFeatureExist) {
      const newFeatures = features.map((feature: any) => {
        if (feature.code === code) {
          return {
            ...feature,
            featureValues: { value: null, isDeleted: true },
          };
        }
        return feature;
      });
      setFeatures(newFeatures);
      formProps.form.setFieldsValue({ features: { features: newFeatures } });
    } else {
      const newFeatures = features.filter(
        (feature: any) => feature.code !== code
      );
      setFeatures(newFeatures);
      formProps.form.setFieldsValue({ features: { features: newFeatures } });
    }
  };

  const handleCode = (name: string) => {
    return name
      .normalize("NFD")
      .replace(/[\u0300-\u036f]/g, "")
      .replace(/ç/g, "c")
      .replace(/ğ/g, "g")
      .replace(/ı/g, "i")
      .replace(/ö/g, "o")
      .replace(/ş/g, "s")
      .replace(/ü/g, "u")
      .toUpperCase()
      .replace(/i/g, "İ")
      .replace(/\s+/g, "")
      .trim();
  };

  //----Add feature to product
  const handleAddFeature = () => {
    if (newFeature.name && newFeature.value) {
      if (features) {
        const existedFeature = features.find((feature) => {
          return (
            feature.name.toLowerCase().trim() ===
            newFeature.name.toLowerCase().trim()
          );
        });

        if (existedFeature && existedFeature.featureValues.isDeleted) {
          const updatedFeatures = features.map((feature) => {
            if (
              feature.name.toLowerCase().trim() ===
              newFeature.name.toLowerCase().trim()
            ) {
              return {
                ...feature,
                featureValues: {
                  value: newFeature.value,
                  isDeleted: false,
                  updatedAt: new Date(Date.now()),
                },
              };
            }
            return feature;
          });

          setFeatures(updatedFeatures);
          setNewFeature({ name: "", value: "" });

          formProps.form.setFieldsValue({
            updatedFeatures: {
              features: updatedFeatures,
            },
          });
          return;
        }

        if (existedFeature && !existedFeature.featureValues.isDeleted) {
          setAlertVisible(true);
          return;
        }

        const newFeatureItem = {
          name: newFeature.name,
          code: handleCode(newFeature.name),
          featureValues: {
            value: newFeature.value,
            updatedAt: new Date(Date.now()),
            isDeleted: false,
          },
        };

        const updatedFeatures = [...features, newFeatureItem];
        setFeatures(updatedFeatures);
        setNewFeature({ name: "", value: "" });

        // Form değerlerini doğru şekilde güncelle
        formProps.form.setFieldsValue({
          updatedFeatures: {
            features: updatedFeatures,
          },
        });
      } else {
        const initialFeature = [
          {
            name: newFeature.name,
            code: handleCode(newFeature.name),
            featureValues: {
              value: newFeature.value,
              updatedAt: new Date(Date.now()),
              isDeleted: false,
            },
          },
        ];

        setFeatures(initialFeature);
        setNewFeature({ name: "", value: "" });

        // Form değerlerini doğru şekilde güncelle
        formProps.form.setFieldsValue({
          updatedFeatures: {
            features: initialFeature,
          },
        });
      }
    }
  };
  const showModal = () => {
    setIsModalVisible(true);
  };

  const resetForm = useCallback(() => {
    let updatedFeatures = queryResult.data?.data.updatedFeatures?.features;
    let defaultFeatures = queryResult.data?.data.features?.features;
    setUpdatedFeatures(updatedFeatures);
    setCurrentFeatures(defaultFeatures);
    setFeatures(updatedFeatures ? updatedFeatures : defaultFeatures);
    formProps.form.setFieldsValue({
      features: {
        features: updatedFeatures ? updatedFeatures : defaultFeatures,
      },
    });
  }, [queryResult.data, formProps.form]);

  // QueryResult değiştiğinde form verilerini güncelle
  useEffect(() => {
    resetForm();
  }, [resetForm]);

  const getEposValueIfExist = (featureName: string) => {
    if (currentFeatures) {
      const feature = currentFeatures.find(
        (feature: any) => feature.name === featureName
      );
      if (feature) {
        return feature.featureValues.value;
      }
      return "Bu özellik epos tarafından eklenmemiştir.";
    }
    return "Bu özellik epos tarafından eklenmemiştir.";
  };

  const changeExistingFeature = (featureName: string, value: string) => {
    const feature = features.find(
      (feature: any) => feature.name === featureName
    );
    if (feature) {
      feature.featureValues.value = value;
    }
  };

  const requestEdit = async (values) => {
    const { image } = values;

    let featuresToUpdated = queryResult.data?.data.updatedFeatures
      ? queryResult.data?.data.updatedFeatures
      : queryResult.data?.data.features;
    if (!featuresToUpdated) {
      featuresToUpdated = { features: [] };
    }

    featuresToUpdated.features = features;

    let base64String;
    for (const file of image) {
      if (file.originFileObj) {
        base64String = await file2Base64(file);
      }
    }
    return (
      formProps.onFinish &&
      formProps.onFinish({
        ...values,
        updatedFeatures: featuresToUpdated,
        image: base64String,
      })
    );
  };

  return (
    <Modal
      {...modalProps}
      title="Ürün Ekle"
      okText="Kaydet ve Kapat"
      onCancel={(e) => {
        handleModalClose(e);
      }}
    >
      <Form {...formProps} onFinish={requestEdit} layout="vertical">
        <Row gutter={[16, 16]}>
          <Col span={12}>
            <Form.Item>
              <Form.Item
                name="image"
                valuePropName="fileList"
                getValueFromEvent={getValueFromEvent}
                getValueProps={(data) => [data] as any}
                noStyle
              >
                <Upload.Dragger
                  accept=".png"
                  beforeUpload={() => false}
                  multiple={false}
                  maxCount={1}
                  onChange={onChange}
                  showUploadList={false}
                  fileList={[]}
                >
                  {renderDraggerContent()}
                </Upload.Dragger>
              </Form.Item>
            </Form.Item>
            <span
              style={{
                fontSize: "12px",
                color: "#8C8C8C",
                fontWeight: 300,
                letterSpacing: "0.5px",
              }}
            >
              min
              <strong> 1080x1080 </strong>
              transparan
              <strong> .png </strong>
              olmalı
            </span>
          </Col>
          <Col span={12}>
            <Form.Item
              label="Dosya İsmi"
              name="name"
              validateTrigger="onBlur"
              rules={[
                {
                  required: true,
                  message: "Dosya ismi alanı boş olamaz.",
                },
              ]}
            >
              <Input />
            </Form.Item>

            <Form.Item
              label="Durumu"
              name="isActive"
              rules={[
                {
                  required: true,
                  message: "Dosya durumunu seçiniz.",
                },
              ]}
            >
              <Radio.Group
                defaultValue={1}
                options={[
                  {
                    label: "Aktif",
                    value: true,
                  },
                  {
                    label: "Pasif",
                    value: false,
                  },
                ]}
              />
            </Form.Item>

            <Form.Item<Assets.AssetItem>
              label="Etiketler"
              name="tags"
              rules={[
                {
                  required: true,
                  message: "Dosya için tag seçiniz.",
                },
              ]}
              getValueProps={(tags?: { id: string }[]) => {
                return { value: tags?.map((store) => store.id) };
              }}
              getValueFromEvent={(args: string[]) => {
                return args.map((item) => ({
                  id: item,
                }));
              }}
            >
              <Select
                {...tagsFilterSelectProps}
                style={{ width: "100%" }}
                mode="multiple"
              />
            </Form.Item>
            <Divider />
            <Col span={24}>
              <label>Ürün Satış Durumu</label>
              <Row gutter={[16, 16]}>
                <Col span={12}>
                  <Form.Item<Assets.AssetItem>
                    label="Promosyon için uygun mu?"
                    name="isAvailableForPromotion"
                    rules={[
                      {
                        required: true,
                        message: "Promosyon için uygun alanı boş olamaz.",
                      },
                    ]}
                    labelCol={{ style: { fontWeight: "bold" } }}
                  >
                    <Radio.Group
                      defaultValue={
                        queryResult.data?.data.isAvailableForPromotion ? 1 : 0
                      }
                      options={[
                        {
                          label: "Evet",
                          value: true,
                        },
                        {
                          label: "Hayır",
                          value: false,
                        },
                      ]}
                    />
                  </Form.Item>
                </Col>

                <Col span={12}>
                  <Form.Item<Assets.AssetItem>
                    label="Pasaj ürünü mü?"
                    name="isPasajProduct"
                    rules={[
                      {
                        required: true,
                        message: "Pasaj ürünü mü alanı boş olamaz.",
                      },
                    ]}
                    labelCol={{ style: { fontWeight: "bold" } }}
                  >
                    <Radio.Group
                      defaultValue={
                        queryResult.data?.data.isPasajProduct ? 1 : 0
                      }
                      options={[
                        {
                          label: "Evet",
                          value: true,
                        },
                        {
                          label: "Hayır",
                          value: false,
                        },
                      ]}
                    />
                  </Form.Item>
                </Col>

                <Col span={12}>
                  <Form.Item<Assets.AssetItem>
                    label="Servis ürünü mü?"
                    name="isServiceProduct"
                    rules={[
                      {
                        required: true,
                        message: "Servis ürünü mü alanı boş olamaz.",
                      },
                    ]}
                    labelCol={{ style: { fontWeight: "bold" } }}
                  >
                    <Radio.Group
                      defaultValue={
                        queryResult.data?.data.isServiceProduct ? 1 : 0
                      }
                      options={[
                        {
                          label: "Evet",
                          value: true,
                        },
                        {
                          label: "Hayır",
                          value: false,
                        },
                      ]}
                    />
                  </Form.Item>
                </Col>

                <Col span={12}>
                  <Form.Item<Assets.AssetItem>
                    label="Yedek parça ürünü mü?"
                    name="isSpareProduct"
                    rules={[
                      {
                        required: true,
                        message: "Yedek parça ürünü mü alanı boş olamaz.",
                      },
                    ]}
                    labelCol={{ style: { fontWeight: "bold" } }}
                  >
                    <Radio.Group
                      defaultValue={
                        queryResult.data?.data.isSpareProduct ? 1 : 0
                      }
                      options={[
                        {
                          label: "Evet",
                          value: true,
                        },
                        {
                          label: "Hayır",
                          value: false,
                        },
                      ]}
                    />
                  </Form.Item>
                </Col>
              </Row>
            </Col>
            <Divider />
          </Col>
        </Row>
        <Divider />
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            marginBottom: "16px",
            flexWrap: "wrap",
          }}
        >
          <span
            style={{
              fontSize: "18px",
              fontWeight: "bold",
              textAlign: "center",
              flex: "1",
            }}
          >
            Ürün Özellikleri
          </span>
          <Button type="primary" onClick={showModal} style={{ flexShrink: 0 }}>
            Özellik Ekle
          </Button>
        </div>

        <Modal
          //{...modalProps}
          title="Yeni Özellik Ekle"
          visible={isModalVisible}
          onOk={() => {
            handleAddFeature();
          }}
          onCancel={() => {
            setIsModalVisible(false);
            setAlertVisible(false);
          }}
          okText="Ekle"
          cancelText="İptal"
        >
          <Form layout="vertical">
            <Row gutter={[16, 16]}>
              {alertVisible && (
                <Alert
                  message="Bu özellik adı zaten mevcut!"
                  type="warning"
                  showIcon
                  closable
                  onClose={() => setAlertVisible(false)}
                  style={{ marginTop: 16 }}
                />
              )}
              <Col span={12}>
                <Form.Item label="Yeni Özellik Adı">
                  <Input
                    value={newFeature.name}
                    onChange={(e) =>
                      setNewFeature({ ...newFeature, name: e.target.value })
                    }
                  />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item label="Yeni Özellik Değeri">
                  <Input
                    value={newFeature.value}
                    onChange={(e) =>
                      setNewFeature({ ...newFeature, value: e.target.value })
                    }
                  />
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </Modal>
        <Divider />

        <Row gutter={[16, 16]} key={"asdas"}>
          <Col key={queryResult?.data?.data?.brand} xs={24} sm={12} md={6}>
            <Form.Item
              key={queryResult?.data?.data?.brand}
              label={"Marka"}
              name={"brand"}
              initialValue={queryResult?.data?.data?.brand}
              labelCol={{ style: { fontWeight: "bold" } }}
            >
              <Input />
            </Form.Item>
          </Col>

          <Divider />

          <Row gutter={[24, 24]} style={{ minWidth: 100 }}>
            {features?.map((feature, index) => {
              if (feature?.featureValues && !feature.featureValues.isDeleted) {
                return (
                  <Col
                    key={index}
                    xs={24}
                    sm={12}
                    md={8}
                    lg={6}
                    style={{
                      marginBottom: 16,
                      display: "flex",
                      flexDirection: "column",
                    }}
                  >
                    <div
                      style={{
                        position: "relative",
                        width: "100%",
                        minWidth: 100,
                        marginBottom: 8,
                      }}
                    >
                      <div
                        style={{
                          position: "absolute",
                          top: "-20px",
                          right: "0",
                          display: "flex",
                          alignItems: "center",
                          zIndex: 1,
                        }}
                      >
                        <Tooltip
                          title={() => getEposValueIfExist(feature.name)}
                        >
                          <Icons.HistoryOutlined style={{ marginRight: 8 }} />
                        </Tooltip>
                        <Button
                          type="text"
                          shape="circle"
                          icon={<Icons.CloseCircleOutlined />}
                          danger
                          onClick={() => handleRemoveFeature(feature.code)}
                        />
                      </div>

                      <Form.Item
                        key={feature.name}
                        label={feature.name}
                        name={[
                          "updatedFeatures",
                          "features",
                          index,
                          "featureValues",
                          "value",
                        ]}
                        rules={[
                          {
                            required: true,
                            message:
                              "Özellik boş bırakılamaz. Silin veya tekrar düzenleyin.",
                          },
                        ]}
                        initialValue={feature.featureValues.value}
                        labelCol={{
                          span: 24,
                          style: {
                            minWidth: 100,
                            fontWeight: "bold",
                            whiteSpace: "normal",
                          },
                        }}
                        wrapperCol={{
                          span: 24,
                        }}
                        style={{
                          minWidth: 100,
                          marginBottom: 0,
                        }}
                      >
                        <Input
                          onChange={(e) => {
                            changeExistingFeature(feature.name, e.target.value);
                          }}
                        />
                      </Form.Item>
                    </div>
                  </Col>
                );
              }
              return null;
            })}
          </Row>
        </Row>
      </Form>
    </Modal>
  );
};
