import {
  IResourceComponentsProps,
  useTranslate,
  useExport,
  CrudFilters,
} from "@pankod/refine-core";

import {
  List,
  Table,
  Tag,
  useTable,
  EditButton,
  Icons,
  FilterDropdown,
  Typography,
  useSelect,
  Select,
  useModalForm,
  Radio,
  Input,
  ExportButton,
  DateField,
  Card,
  Form,
  DatePicker,
  FormProps,
  Button,
  TextField,
  Grid,
} from "@pankod/refine-antd";

import dayjs from "dayjs";

import { ModerationShow } from "./";

import { Shops, Contents, Users } from "interfaces";
import { ContentStatus } from "interfaces/enums";

const { Title } = Typography;

export const ModerationList: React.FC<IResourceComponentsProps> = () => {
  const { tableProps, tableQueryResult, searchFormProps, sorter, filters } =
    useTable<Contents.ContentItem>({
      onSearch: (params: any) => {
        const filters: CrudFilters = [];
        const { createdAt } = params;

        filters.push(
          {
            field: "createdAt",
            operator: "gte",
            value: createdAt
              ? dayjs(createdAt[0]).startOf("day").toISOString()
              : undefined,
          },
          {
            field: "expireDate",
            operator: "lte",
            value: createdAt
              ? dayjs(createdAt[1]).endOf("day").toISOString()
              : undefined,
          }
        );

        return filters;
      },
    });

  const { triggerExport, isLoading } = useExport<Contents.ContentItem>({
    mapData: (item) => {
      // content type
      const productContents = [
        "product-discount-price",
        "product-discount-percent",
        "product-discount-gift",
      ];
      const campaignContents = ["campaign", "double-campaign"];
      const simCardContents = ["sim-1", "sim-2"];

      let contentType;
      if (productContents.includes(item.template.resourceSlug)) {
        contentType = "Ürün Kampanyası";
      } else if (campaignContents.includes(item.template.resourceSlug)) {
        contentType = "Tarife Kampanyası";
      } else if (simCardContents.includes(item.template.resourceSlug)) {
        contentType = "532'li Hat Kampanyası";
      } else {
        contentType = "HR";
      }

      // Ürün
      let product = "-";
      const productParams: any = item.parameters.find(
        (i: any) => i.name === "product"
      );
      if (productParams) {
        product = productParams.value.name;
      }

      // Hediye
      let gift = "-";
      const giftParams: any = item.parameters.find(
        (i: any) => i.name === "gift"
      );
      if (giftParams) {
        gift = giftParams.value.name;
      }

      // Fiyat
      let price = "-";
      const priceParams: any = item.parameters.find((i: any) =>
        ["price", "price-1"].includes(i.name)
      );
      if (priceParams) {
        price = priceParams.value;
      }

      let price2 = "-";
      const price2Params: any = item.parameters.find((i: any) =>
        ["price-2"].includes(i.name)
      );
      if (price2Params) {
        price2 = price2Params.value;
      }

      // İndirimli Fiyat
      let discountedPrice = "-";
      const discountedPriceParams: any = item.parameters.find(
        (i: any) => i.name === "discountedPrice"
      );
      if (discountedPriceParams) {
        discountedPrice = discountedPriceParams.value;
      }

      // Taksit
      let installment = "-";
      const installmentParams: any = item.parameters.find(
        (i: any) => i.name === "installment"
      );
      if (installmentParams) {
        installment = installmentParams.value;
      }

      // İndirim Oranı
      let percent = "-";
      const percentParams: any = item.parameters.find(
        (i: any) => i.name === "percent"
      );
      if (percentParams) {
        percent = percentParams.value;
      }

      // İnternet
      let internet = "-";
      const internetParams: any = item.parameters.find((i: any) =>
        ["internet", "internet-1"].includes(i.name)
      );
      if (internetParams) {
        internet = internetParams.value;
      }

      // İnternet 2
      let internet2 = "-";
      const internet2Params: any = item.parameters.find((i: any) =>
        ["internet-2"].includes(i.name)
      );
      if (internet2Params) {
        internet2 = internet2Params.value;
      }

      // Dakika
      let call = "-";
      const callParams: any = item.parameters.find((i: any) =>
        ["call", "call-1"].includes(i.name)
      );
      if (callParams) {
        call = callParams.value;
      }

      // Dakika - 2
      let call2 = "-";
      const call2Params: any = item.parameters.find((i: any) =>
        ["call-2"].includes(i.name)
      );
      if (call2Params) {
        call2 = call2Params.value;
      }

      // Dakika
      let sms = "-";
      const smsParams: any = item.parameters.find((i: any) =>
        ["sms", "sms-1"].includes(i.name)
      );
      if (smsParams) {
        sms = smsParams.value;
      }

      // Dakika - 2
      let sms2 = "-";
      const sms2Params: any = item.parameters.find((i: any) =>
        ["sms-2"].includes(i.name)
      );
      if (sms2Params) {
        sms2 = sms2Params.value;
      }

      return {
        "Mağaza Kodu": item.shop.shopCode,
        "Mağaza İsmi": item.shop.name,
        "Satış Yöneticisi":
          item.shop.users.length > 0 ? item.shop.users[0].fullName : "-",
        "İçerik Tipi": contentType,
        Ürün: product,
        Hediye: gift,
        "Ürün Fiyatı/Tarife Fiyatı": price,
        "2. Tarife Fiyatı": price2,
        "Ürünün İndirimli Fiyatı": discountedPrice,
        Taksit: installment,
        "Ürünün İndirim Oranı": percent,
        "Tarife Kampanyası 1. Tarife İnternet": internet,
        "Tarife Kampanyası 2. Tarife İnternet": internet2,
        "Tarife Kampanyası 1. Tarife Dakika": call,
        "Tarife Kampanyası 2. Tarife Dakika": call2,
        "Tarife Kampanyası 1. Tarife SMS": sms,
        "Tarife Kampanyası 2. Tarife SMS": sms2,
        "Başlangıç Tarihi": dayjs(item.createdAt).format("LL"),
        "Bitiş Tarihi": dayjs(item.expireDate).format("LL"),
      };
    },
    sorter,
    filters,
    pageSize: 100,
  });

  const { sm } = Grid.useBreakpoint();

  const t = useTranslate();

  const { selectProps: shopsFilterSelectProps } = useSelect<any>({
    fetchSize: 5000,
    resource: "omma-shops",
    optionLabel: "name",
    optionValue: "id",
  });

  const { queryResult, show, modalProps } = useModalForm<Contents.ContentItem>({
    action: "edit",
  });

  const { selectProps: userFilterSelectProps } = useSelect<Users.UserItem>({
    resource: "users",
    optionLabel: "fullName",
    optionValue: "id",
  });

  return (
    <>
      {sm && (
        <Card
          style={{ marginBottom: 20 }}
          bodyStyle={{ display: "flex", justifyContent: "end" }}
        >
          <Filter formProps={searchFormProps} />
        </Card>
      )}
      <List
        title=""
        pageHeaderProps={{
          extra: sm ? (
            <ExportButton
              onClick={triggerExport}
              loading={isLoading}
              hideText
            />
          ) : undefined,
        }}
      >
        <Table {...tableProps} scroll={{ x: 1 }} rowKey="id">
          <Table.Column
            dataIndex={["user", "fullName"]}
            title="İsim"
            responsive={["sm"]}
          />

          <Table.Column<Contents.ContentItem>
            dataIndex={["shop", "users"]}
            title="Satış Yöneticisi"
            responsive={["sm"]}
            key="shop.users.id"
            render={(val) => {
              if (val.length > 0) {
                return <TextField value={val[0].fullName} />;
              }

              return <TextField value={"-"} />;
            }}
            filterDropdown={(props) => {
              return (
                <FilterDropdown {...props}>
                  <Title level={5}>Aramak istediğiniz ismi yazın</Title>
                  <Select
                    {...userFilterSelectProps}
                    style={{ width: "100%" }}
                  />
                </FilterDropdown>
              );
            }}
          />

          <Table.Column<Contents.ContentItem>
            dataIndex={["shop", "name"]}
            title="Bağlı Mağaza"
            key="shop.id"
            filterDropdown={(props) => {
              return (
                <FilterDropdown {...props}>
                  <Title level={5}>Aramak istediğiniz mağazayı yazın</Title>
                  <Select
                    {...shopsFilterSelectProps}
                    style={{ width: "100%" }}
                    mode="multiple"
                  />
                </FilterDropdown>
              );
            }}
          />
          <Table.Column<Contents.ContentItem>
            dataIndex={["shop", "shopCode"]}
            title="Bağlı Mağaza Kodu"
            key="shop.shopCode"
            responsive={["sm"]}
            filterDropdown={(props) => {
              return (
                <FilterDropdown {...props}>
                  <Title level={5}>
                    Aramak istediğiniz mağazayı kodunu yazın
                  </Title>
                  <Input />
                </FilterDropdown>
              );
            }}
          />
          <Table.Column dataIndex="name" title="Kampanya Adı" />
          <Table.Column<Contents.ContentItem>
            dataIndex="status"
            title="Durum"
            render={(_, record) => {
              const { status } = record;

              const text = t(`Enums.ContentStatus.${status}`);
              switch (status) {
                case "waiting_approval":
                  return (
                    <Tag color="processing" icon={<Icons.ReloadOutlined />}>
                      {text}
                    </Tag>
                  );
                case "published":
                  return (
                    <Tag color="success" icon={<Icons.CheckOutlined />}>
                      {text}
                    </Tag>
                  );
                case "rejected":
                  return (
                    <Tag color="warning" icon={<Icons.StopOutlined />}>
                      {text}
                    </Tag>
                  );
                case "stopped":
                  return (
                    <Tag color="red" icon={<Icons.WarningOutlined />}>
                      {text}
                    </Tag>
                  );

                case "done":
                  return (
                    <Tag color="lime" icon={<Icons.CheckOutlined />}>
                      {text}
                    </Tag>
                  );

                default:
                  return <Tag>{status}</Tag>;
              }
            }}
            filterDropdown={(props) => {
              const options = [
                {
                  label: "Onay Bekliyor",
                  value: ContentStatus.WAITING_APPROVAL,
                },
                { label: "Yayında", value: ContentStatus.PUBLISHED },
                { label: "Reddedildi", value: ContentStatus.REJECTED },
                { label: "Durduruldu", value: ContentStatus.STOPPED },
                { label: "Tamamlandı", value: ContentStatus.DONE },
              ];

              return (
                <FilterDropdown {...props}>
                  <Title level={5}>Durum</Title>
                  <Radio.Group style={{ width: 150 }} options={options} />
                </FilterDropdown>
              );
            }}
          />
          <Table.Column<Contents.ContentItem>
            dataIndex="createdAt"
            title="Yayın Aralığı"
            responsive={["sm"]}
            render={(_, record) => (
              <div style={{ display: "flex", flexDirection: "column" }}>
                <DateField format="D MMMM YYYY" value={record.createdAt} />
                <DateField format="D MMMM YYYY" value={record.expireDate} />
              </div>
            )}
          />

          <Table.Column<Contents.ContentItem>
            title="İşlemler"
            dataIndex="actions"
            render={(_, record) => (
              <EditButton
                size="small"
                onClick={() => {
                  show(record.id);
                }}
                icon={<Icons.FolderViewOutlined />}
              >
                Kampanyayı İncele
              </EditButton>
            )}
          />
        </Table>
      </List>
      <ModerationShow
        queryResult={queryResult}
        tableQueryResult={tableQueryResult}
        modalProps={modalProps}
      />
    </>
  );
};

const Filter: React.FC<{ formProps: FormProps }> = ({ formProps }) => {
  const { RangePicker } = DatePicker;
  return (
    <Form layout="inline" {...formProps}>
      <Form.Item label="Yayın Aralığı" name="createdAt">
        <RangePicker
          size="small"
          placeholder={["Başlangıç Tarihi", "Bitiş Tarihi"]}
        />
      </Form.Item>
      <Form.Item>
        <Button size="small" htmlType="submit" type="primary">
          Filtrele
        </Button>
      </Form.Item>
    </Form>
  );
};
