import { Pie } from "@ant-design/charts";
import { Skeleton, Row, Col } from "@pankod/refine-antd";
import { IResourceComponentsProps, useCustom } from "@pankod/refine-core";
import React from "react";
import ENV from "utils/env";
import { useDashboard } from "dashboardProvider";

export const AttendedUnattendedBranchCountPie: React.FC<
  IResourceComponentsProps
> = () => {
  const { selectedDashboard } = useDashboard();

  const {
    data: response,
    isFetching,
    isError,
  } = useCustom({
    url: `${ENV.API_URL}/api/campaign/lottery/unAttendedBranchDetails`,
    method: "get",
    config: {
      query: {
        dbkey: selectedDashboard,
      },
    },
  });

  const {
    data: countResponse,
    isError: isCountError,
    isFetching: isCountFetching,
  } = useCustom({
    url: `${ENV.API_URL}/api/campaign/lottery/attendCountsBasedOnCities`,
    method: "get",
    config: {
      query: {
        dbkey: selectedDashboard,
      },
    },
  });

  const {
    data: uniqueAttendsCountResponse,
    isError: isUniqueAttendsCountError,
    isFetching: isUniqueAttendsCountFetching,
  } = useCustom({
    url: `${ENV.API_URL}/api/campaign/lottery/uniqueAttends`,
    method: "get",
    config: {
      query: {
        dbkey: selectedDashboard,
      },
    },
  });

  const dataSource = countResponse?.data?.results.map((item: any) => {
    return {
      key: item.city,
      city: item.city,
      count: item.count,
    };
  });

  const totalCount = dataSource?.reduce((accumulator, currentValue) => {
    return accumulator + currentValue.count;
  }, 0);

  if (isFetching) {
    return <Skeleton />;
  }

  if (isError) {
    return (
      <span style={{ fontWeight: 800, fontSize: 20 }}>Veri bulunamadı.</span>
    );
  }

  let config = {
    data: [
      {
        type: "Katılım Sağlanan Şube Sayısı",
        value: response?.data?.playedBranchesCount,
      },
      {
        type: "Katılım Sağlanmayan Şube Sayısı",
        value: response?.data?.unPlayedBranchesCount,
      },
    ],
    angleField: "value",
    colorField: "type",
    paddingRight: 80,
    label: {
      text: "value",
      position: "outside",
    },
    legend: {
      color: {
        title: true,
        position: "left",
        rowPadding: 0,
      },
    },
  };

  return (
    <div>
      <Row gutter={[16, 16]}>
        <Col span={24}>
          {isCountFetching ? (
            <Skeleton />
          ) : (
            <span>Toplam Katılım: {totalCount}</span>
          )}
          {isCountError ? <span>Katılım Sayısı:Veri bulunamadı.</span> : null}
        </Col>
        <Col span={24}>
          {isUniqueAttendsCountFetching ? (
            <Skeleton />
          ) : (
            <span>
              Toplam Tekil Katılım Sayısı:{" "}
              {uniqueAttendsCountResponse?.data.total[0].total}
            </span>
          )}
          {isUniqueAttendsCountError ? (
            <span>Tekil Katılım Sayısı:Veri bulunamadı.</span>
          ) : null}
        </Col>
      </Row>
      <Pie {...config} />
    </div>
  );
};
