import { Form, Input, Select, Radio, Modal, ModalProps, FormProps } from "@pankod/refine-antd";

import { TagTypes } from "interfaces/enums";

type Props = {
  modalProps: ModalProps;
  formProps: FormProps;
};

export const TagsCreate: React.FC<Props> = ({ formProps, modalProps }) => {
  return (
    <Modal {...modalProps} title="Kullanıcı Ekle" width={400}>
      <Form
        {...formProps}
        layout="vertical"
        initialValues={{
          isActive: true,
        }}
      >
        <Form.Item
          label="Etiket Adı"
          name="name"
          validateTrigger="onBlur"
          rules={[
            {
              required: true,
              message: "Etiket adı boş olamaz.",
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="Açıklama"
          name="description"
          validateTrigger="onBlur"
          rules={[
            {
              required: true,
              message: "Etiket açıklaması boş olamaz.",
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="Etiket Tipi"
          name="type"
          validateTrigger="onBlur"
          rules={[
            {
              required: true,
              message: "Etiket tipi boş olamaz.",
            },
          ]}
        >
          <Select
            options={[
              {
                value: TagTypes.ASSET,
                label: "Asset",
              },
              {
                value: TagTypes.TEMPLATE,
                label: "Template",
              },
            ]}
          />
        </Form.Item>

        <Form.Item
          label="Durumu"
          name="isActive"
          rules={[
            {
              required: true,
              message: "Etiket durumunu seçiniz.",
            },
          ]}
        >
          <Radio.Group
            options={[
              {
                label: "Aktif",
                value: true,
              },
              {
                label: "Pasif",
                value: false,
              },
            ]}
          />
        </Form.Item>
      </Form>
    </Modal>
  );
};
