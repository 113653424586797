import { useGetIdentity } from "@pankod/refine-core";
import {
  Card,
  Avatar,
  Tag,
  Typography,
  Icons,
  Row,
  Col,
} from "@pankod/refine-antd";
import { Roles } from "interfaces";
import { RoleTypes } from "interfaces/enums";

const { UserOutlined } = Icons;
const { Title } = Typography;

export const DashboardPage: React.FC = () => {
  const { data: user, isLoading } = useGetIdentity<{
    fullName: string;
    gsm: string;
    email: string;
    role: Roles.RoleItem;
    shops: [{ name: string; code: string }];
    ommaShops: any;
  }>();

  const renderShopInfo = () => {
    if (user?.role.type === RoleTypes.SALES_MANAGER) {
      return (
        <div style={{ marginBottom: 20 }}>
          <div style={{ marginBottom: 3, fontSize: 16 }}>Bağlı Mağazalar</div>
          <div>
            {user?.ommaShops.map((shop, index) => (
              <Tag key={`tag--${index * 1}`}>{shop.name}</Tag>
            ))}
          </div>
        </div>
      );
    }

    if (user?.role.type === RoleTypes.SHOP_MANAGER) {
      const shop = user?.ommaShops[0];

      if (!shop) return null;

      return (
        <>
          <div style={{ marginBottom: 20 }}>
            <div style={{ fontSize: 16 }}>Mağaza Adı</div>
            <Title level={4}>{shop.name}</Title>
          </div>

          <div style={{ marginBottom: 20 }}>
            <div style={{ fontSize: 16 }}>Mağaza Kodu</div>
            <Title level={4}>{shop.shopCode}</Title>
          </div>
        </>
      );
    }

    return null;
  };

  return (
    <Card
      loading={isLoading}
      style={{ marginBottom: 10 }}
      bodyStyle={{ minHeight: "500px" }}
    >
      <div style={{ display: "flex", marginBottom: 30 }}>
        <Avatar size="large" style={{ marginRight: 6 }}>
          <UserOutlined />
        </Avatar>
        <Title level={2}>{user?.fullName}</Title>
      </div>

      <Row>
        <Col span={12}>
          <div style={{ marginBottom: 20 }}>
            <div style={{ fontSize: 16 }}>Kullanıcı Rolü</div>
            <Title level={4}>{user?.role.name}</Title>
          </div>

          <div style={{ marginBottom: 20 }}>
            <div style={{ fontSize: 16 }}>GSM</div>
            <Title level={4}>{user?.gsm}</Title>
          </div>

          <div style={{ marginBottom: 20 }}>
            <div style={{ fontSize: 16 }}>E-Posta</div>
            <Title level={4}>{user?.email}</Title>
          </div>
        </Col>
        <Col span={12}>{renderShopInfo()}</Col>
      </Row>
    </Card>
  );
};
