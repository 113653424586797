import { useGetIdentity, useLogout, useTranslate } from "@pankod/refine-core";
import { AntdLayout, Button, Icons, Avatar, Grid } from "@pankod/refine-antd";
import routerProvider from "@pankod/refine-react-router-v6";

const { MenuFoldOutlined, UserOutlined } = Icons;
const { Link } = routerProvider;

export const Header: React.FC<{
  collapsed?: boolean;
  setCollapsed?: React.Dispatch<React.SetStateAction<boolean>>;
}> = ({ collapsed, setCollapsed }) => {
  const t = useTranslate();
  const { data: user } =
    useGetIdentity<{ fullName: string; role: { name: string } }>();
  const { mutate: logout } = useLogout();
  const screens = Grid.useBreakpoint();

  return (
    <AntdLayout.Header className="header">
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <MenuFoldOutlined
          onClick={() => setCollapsed((prevState) => !prevState)}
          style={{ fontSize: 20, visibility: "hidden" }}
        />
        <div style={{ display: "flex", alignItems: "center" }}>
          <Avatar
            style={{ backgroundColor: "#00B6F1" }}
            icon={<UserOutlined />}
          />

          {!screens.xs && (
            <Link to="/">
              <Button type="link">{`${user?.fullName} (${user?.role?.name})`}</Button>
            </Link>
          )}

          <span
            style={{
              marginLeft: !screens.xs ? 6 : 12,
            }}
          >
            |
          </span>

          <Button className="px-0" type="link" onClick={() => logout()}>
            {t("buttons.logout")}
          </Button>
        </div>
      </div>
    </AntdLayout.Header>
  );
};
