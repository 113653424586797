import { Line } from "@ant-design/charts";
import { DatePicker, Skeleton } from "@pankod/refine-antd";
import { IResourceComponentsProps, useCustom } from "@pankod/refine-core";
import { CitySelector } from "components/CitySelector/cityselector";
import dayjs from "dayjs";
import React from "react";
import ENV from "utils/env";
import { useDashboard } from "dashboardProvider";

export const AttendedCountLine: React.FC<IResourceComponentsProps> = () => {
  const [selectedDates, setSelectedDates] = React.useState<[Date, Date] | null>(
    () => {
      const today = new Date();
      const tomorrow = new Date(today);
      tomorrow.setDate(today.getDate() + 1);

      const oneWeekAgo = new Date(today);
      oneWeekAgo.setDate(today.getDate() - 7);

      return [oneWeekAgo, tomorrow];
    }
  );

  const [selectedCity, setSelectedCity] = React.useState<string | null>(null);
  const { selectedDashboard } = useDashboard();
  const {
    data: response,
    isFetching,
    isError,
  } = useCustom({
    url: `${ENV.API_URL}/api/campaign/lottery/mostAttendedDays?dbkey=${selectedDashboard}`,
    method: "post",
    queryOptions: {
      retry: 0,
      enabled: true,
    },
    config: {
      payload: {
        startDate: selectedDates ? selectedDates[0] : null,
        endDate: selectedDates ? selectedDates[1] : null,
        city: selectedCity ? selectedCity : null,
      },
    },
  });

  const handleChange = (date, dateArray) => {
    if (dateArray[0] !== "" && dateArray[1] !== "") {
      setSelectedDates(dateArray);
    } else {
      setSelectedDates(null);
    }
  };

  const disabledDate = (current) => {
    // Disable future dates, but allow today
    return current && current > dayjs().endOf("day");
  };

  if (isFetching) {
    return <Skeleton />;
  }
  if (isError) {
    return (
      <div>
        <div
          style={{
            display: "flex",
            flexWrap: "wrap",
            justifyContent: "space-between",
            marginBottom: "16px",
          }}
        >
          <div style={{ flexGrow: 1, marginRight: "8px", marginBottom: "8px" }}>
            <DatePicker.RangePicker
              placeholder={["Başlangıç Tarihi", "Bitiş Tarihi"]}
              onChange={handleChange}
              defaultValue={
                selectedDates
                  ? [dayjs(selectedDates[0]), dayjs(selectedDates[1])]
                  : null
              }
              disabledDate={disabledDate}
              style={{ width: "100%" }}
            />
          </div>
          <div style={{ flexGrow: 1, marginLeft: "8px", marginBottom: "8px" }}>
            <CitySelector
              selectedCity={selectedCity}
              setSelectedCity={setSelectedCity}
            />
          </div>
        </div>
        <div
          style={{ display: "flex", justifyContent: "center", marginTop: 16 }}
        >
          <span style={{ fontWeight: 800, fontSize: 20 }}>
            Bu filtrelere ait veri bulunamadı.
          </span>
        </div>
      </div>
    );
  }
  let data = response.data?.days.map((i) => {
    return {
      day: dayjs(i.day).format("DD/MM/YYYY"),
      value: Number(i.game_count),
    };
  });
  data = data.sort(
    (a, b) =>
      dayjs(a.day, "DD/MM/YYYY").valueOf() -
      dayjs(b.day, "DD/MM/YYYY").valueOf()
  );

  const config = {
    data,
    xField: "day",
    yField: "value",
    point: {
      shapeField: "square",
      sizeField: 4,
    },
    interaction: {
      tooltip: {
        marker: false,
      },
    },
    style: {
      lineWidth: 2,
    },
  };

  return (
    <div>
      <div
        style={{
          display: "flex",
          flexWrap: "wrap",
          justifyContent: "space-between",
          marginBottom: "16px",
        }}
      >
        <div style={{ flexGrow: 1, marginRight: "8px", marginBottom: "8px" }}>
          <DatePicker.RangePicker
            placeholder={["Başlangıç Tarihi", "Bitiş Tarihi"]}
            onChange={handleChange}
            defaultValue={
              selectedDates
                ? [dayjs(selectedDates[0]), dayjs(selectedDates[1])]
                : null
            }
            disabledDate={disabledDate}
            style={{ width: "100%" }}
          />
        </div>
        <div style={{ flexGrow: 1, marginLeft: "8px", marginBottom: "8px" }}>
          <CitySelector
            selectedCity={selectedCity}
            setSelectedCity={setSelectedCity}
          />
        </div>
      </div>
      <Line {...config} />
    </div>
  );
};
