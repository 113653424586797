import { Col, Row, Skeleton, Table } from "@pankod/refine-antd";
import { IResourceComponentsProps, useCustom } from "@pankod/refine-core";
import DownloadMenu from "components/DownloadDropdown";
import dayjs from "dayjs";
import React from "react";
import ENV from "utils/env";
import {
  exportToCsv,
  exportToExcel,
  exportToJson,
} from "utils/utiltyFunctions";
import { useDashboard } from "dashboardProvider";
export const TableMostPlayedHours: React.FC<IResourceComponentsProps> = () => {
  const { selectedDashboard } = useDashboard();
  const {
    data: response,
    isFetching,
    isError,
  } = useCustom({
    url: `${ENV.API_URL}/api/campaign/trivia/mostPlayedHours?dbkey=${selectedDashboard}`,
    method: "post",
    queryOptions: {
      retry: 0,
      enabled: true,
    },
    config: {
      payload: {
        city: null,
      },
    },
  });

  if (isFetching) {
    return <Skeleton />;
  }

  let dataSource = response.data?.hours.map((i) => {
    return {
      key: i.hour,
      hour: i.hour,
      count: i.game_count,
    };
  });
  const thirdTabColumns = [
    {
      title: "Saat",
      dataIndex: "hour",
      key: "hour",
      width: "25%",
    },
    {
      title: "Sayı",
      dataIndex: "count",
      key: "count",
      width: "25%",
    },
  ];

  const updatedAt = response.data?.updatedAt;
  const parsedUpdatedAt = dayjs(updatedAt).format("DD/MM/YYYY HH:mm");
  const formattedDate = updatedAt
    ? dayjs(updatedAt).format("DD-MM-YYYY-HH-mm")
    : "";

  return (
    <div>
      <Row justify="end" gutter={16}>
        <Col>
          <DownloadMenu
            dataSource={dataSource}
            columns={thirdTabColumns}
            formattedDate={formattedDate}
            exportToExcel={exportToExcel}
            exportToCsv={exportToCsv}
            exportToJson={exportToJson}
          />
        </Col>
      </Row>
      <Row>
        <Col span={6}>Son Güncelleme Tarihi:</Col>
        <Col span={6}>
          {dayjs(response.data.updatedAt).format("DD/MM/YYYY HH:mm")}
        </Col>
        <Col span={12} />
      </Row>
      <Table
        dataSource={dataSource}
        columns={thirdTabColumns}
        pagination={{ pageSize: 25, disabled: false }}
        scroll={{ x: 1500 }}
      />
    </div>
  );
};
