import { Contents } from "interfaces";

export function convertBase64(file: File | Blob): Promise<string> {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result as string);
        reader.onerror = (error) => reject(error);
    });
}

export function filterParametersFields(
    values: any,
    matcher: RegExp = /(parameters.*)|date/
): Contents.ContentParameters[] {
    return Object.keys(values)
        .filter((key) => key.match(matcher))
        .map((key) => ({
            name: key.replace(/parameters./, ""),
            value: values[key],
        }));
}

/**
 * Accesses or transforms value based on type
 * @param type typeof entry that conditions depend on
 * @param value to be accessed or transformed conditionally
 */
export const renderDisplayValue = (
    type: string,
    value: Contents.ContentParameters
) => {
    if (type === "asset") {
        return value.name;
    }

    if (type === "input_price") {
        return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    }

    return value;
};

type IArea = {
    fieldName: string;
    lg: number;
};

export const getBounceAreaFields = (slug: string): IArea[][] => {
    let fields: IArea[][] = [];

    switch (slug) {
        case "product-discount-price":
            fields = [
                [
                    {
                        fieldName: "price",
                        lg: 4,
                    },
                    {
                        fieldName: "discountedPrice",
                        lg: 4,
                    },
                    {
                        fieldName: "priceBounceHeight",
                        lg: 8,
                    },
                ],
                [
                    {
                        fieldName: "installment",
                        lg: 8,
                    },
                    {
                        fieldName: "installmentBounceHeight",
                        lg: 8,
                    },
                ],
            ];
            break;
        case "product-discount-percent":
            fields = [
                [
                    {
                        fieldName: "percent",
                        lg: 4,
                    },
                    {
                        fieldName: "percentBounceHeight",
                        lg: 8,
                    },
                ],
                [
                    {
                        fieldName: "installment",
                        lg: 4,
                    },
                    {
                        fieldName: "installmentBounceHeight",
                        lg: 8,
                    },
                ],
            ];
            break;

        case "product-discount-gift":
            fields = [
                [
                    {
                        fieldName: "price",
                        lg: 4,
                    },
                    {
                        fieldName: "discountedPrice",
                        lg: 4,
                    },
                    {
                        fieldName: "priceBounceHeight",
                        lg: 8,
                    },
                ],
                [
                    {
                        fieldName: "giftTextBounceHeight",
                        lg: 8,
                    },
                ],
            ];
            break;

        case "campaign":
            fields = [
                [
                    {
                        fieldName: "internet",
                        lg: 4,
                    },
                    {
                        fieldName: "call",
                        lg: 5,
                    },
                    {
                        fieldName: "sms",
                        lg: 8,
                    },
                ],
                [
                    {
                        fieldName: "price",
                        lg: 8,
                    },
                ],
            ];
        break;

        case "double-campaign-1":
            fields = [
                [
                    {
                        fieldName: "internet-1",
                        lg: 4,
                    },
                    {
                        fieldName: "call-1",
                        lg: 5,
                    },
                    {
                        fieldName: "sms-1",
                        lg: 8,
                    },
                ],
                [
                    {
                        fieldName: "price-1",
                        lg: 8,
                    },
                ],
            ];
        break;

        case "double-campaign-2":
            fields = [
                [
                    {
                        fieldName: "internet-2",
                        lg: 4,
                    },
                    {
                        fieldName: "call-2",
                        lg: 5,
                    },
                    {
                        fieldName: "sms-2",
                        lg: 8,
                    },
                ],
                [
                    {
                        fieldName: "price-2",
                        lg: 8,
                    },
                ],
            ];
        break;

        default:
            fields = [];
            break;
    }

    return fields;
};
