// DownloadMenu.js
import React from "react";
import { Menu, Dropdown, Button } from "antd";
import { DownOutlined } from "@ant-design/icons";

const DownloadMenu = ({
  dataSource,
  formattedDate,
  exportToExcel,
  exportToCsv,
  exportToJson,
  columns,
}) => {
  const menu = (
    <Menu>
      <Menu.Item
        key="1"
        onClick={() =>
          exportToExcel(dataSource, columns, `${formattedDate}.xlsx`)
        }
      >
        Excel formatında indir
      </Menu.Item>
      <Menu.Item
        key="2"
        onClick={() => exportToCsv(dataSource, columns, `${formattedDate}.csv`)}
      >
        CSV formatında indir
      </Menu.Item>
      <Menu.Item
        key="3"
        onClick={() => exportToJson(dataSource, `${formattedDate}.json`)}
      >
        JSON formatında indir
      </Menu.Item>
    </Menu>
  );

  return (
    <Dropdown overlay={menu}>
      <Button type="primary" shape="round" size="large">
        İndir <DownOutlined />
      </Button>
    </Dropdown>
  );
};

export default DownloadMenu;
