import React, { useState, useEffect } from "react";
import { GetOneResponse } from "@pankod/refine-core";

import {
  Form,
  Input,
  Select,
  useSelect,
  useRadioGroup,
  Radio,
  Modal,
  ModalProps,
  FormProps,
  RadioChangeEvent,
} from "@pankod/refine-antd";

import { QueryObserverResult } from "react-query";

import { Roles, Shops, Users } from "interfaces";
import { RoleTypes } from "interfaces/enums";

type Props = {
  modalProps: ModalProps;
  formProps: FormProps;
  queryResult: QueryObserverResult<GetOneResponse<Users.UserItem>, unknown>;
};

export const UserEdit: React.FC<Props> = ({
  formProps,
  modalProps,
  queryResult,
}) => {
  const [selectedRoleType, setSelectedRoleType] = useState<
    RoleTypes | undefined
  >();
  const {
    radioGroupProps: rolesRadioGroupProps,
    queryResult: rolesQueryResult,
  } = useRadioGroup<Roles.RoleItem>({
    resource: "roles",
    optionLabel: "name",
    optionValue: "id",
  });

  useEffect(() => {
    setSelectedRoleType(queryResult.data?.data.role.type);
  }, [queryResult.data]);

  const { selectProps: shopsSelectProps } = useSelect<any>({
    fetchSize: 5000,
    resource: "omma-shops",
    optionLabel: "name",
    optionValue: "id",
    defaultValue: queryResult.data?.data.ommaShops.map((shop) => shop.id),
  });

  const onChangeRole = (roleId: string) => {
    const role = rolesQueryResult.data?.data.find(
      (item: Roles.RoleItem) => item.id === roleId
    );
    if (role) setSelectedRoleType(role.type);
  };

  const renderShopSelect = () => {
    if (!selectedRoleType) {
      return null;
    }

    const viewRoleTypes = [RoleTypes.SALES_MANAGER, RoleTypes.SHOP_MANAGER];
    if (viewRoleTypes.includes(selectedRoleType)) {
      return (
        <Form.Item<Users.UserItem>
          label="Bağlı Mağazalar"
          name="ommaShops"
          rules={[
            {
              required: true,
              message: "Bağlı mağaza seçiniz.",
            },
          ]}
          getValueProps={(ommaShops?: { id: string }[]) => {
            return { value: ommaShops?.map((store) => store.id) };
          }}
          getValueFromEvent={(args: string[]) => {
            return args.map((item) => ({
              id: item,
            }));
          }}
        >
          <Select {...shopsSelectProps} mode="multiple" />
        </Form.Item>
      );
    }

    return null;
  };

  return (
    <Modal {...modalProps}>
      <Form
        {...formProps}
        layout="vertical"
        initialValues={{
          firstName: queryResult.data?.data.firstName,
          lastName: queryResult.data?.data.lastName,
          email: queryResult.data?.data.email,
          gsm: queryResult.data?.data.gsm,
          role: {
            id: queryResult.data?.data.role.id,
          },
          ommaShops: queryResult.data?.data.ommaShops,
          isActive: true,
        }}
      >
        <Form.Item
          name={["role", "id"]}
          label="Kullanıcı Rolü"
          rules={[
            {
              required: true,
              message: "Kullanıcı rolünü seçiniz.",
            },
          ]}
        >
          <Radio.Group
            {...rolesRadioGroupProps}
            onChange={(e: RadioChangeEvent) => {
              onChangeRole(e.target.value);
            }}
          />
        </Form.Item>
        <Form.Item
          label="Kullanıcı Durumu"
          name="isActive"
          rules={[
            {
              required: true,
              message: "Kullanıcı durumunu seçiniz.",
            },
          ]}
        >
          <Radio.Group
            options={[
              {
                label: "Aktif",
                value: true,
              },
              {
                label: "Pasif",
                value: false,
              },
            ]}
          />
        </Form.Item>
        <Form.Item
          label="Adı"
          name="firstName"
          validateTrigger="onBlur"
          rules={[
            {
              required: true,
              message: "Adı alanı boş olamaz.",
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="Soyadı"
          name="lastName"
          validateTrigger="onBlur"
          rules={[
            {
              required: true,
              message: "Soyadı alanı boş olamaz.",
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="Eposta Adresi"
          name="email"
          validateTrigger="onBlur"
          rules={[
            {
              required: true,
              message: "Eposta alanı boş olamaz.",
            },
            {
              type: "email",
              message: "Hatalı eposta adresi",
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="GSM No"
          name="gsm"
          validateTrigger="onBlur"
          rules={[
            {
              required: true,
              message: "Gsm alanı boş olamaz.",
            },

            {
              len: 10,
              message: "Gsm numarası başında 0 olmadan 10 karakter olmalıdır.",
            },
          ]}
        >
          <Input type="number" />
        </Form.Item>

        {renderShopSelect()}
      </Form>
    </Modal>
  );
};
