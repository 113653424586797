// #region Global Imports
import { FC } from "react";
import { useList, useNavigation } from "@pankod/refine-core";
import { Modal, Button, ModalProps, Row, Col } from "@pankod/refine-antd";
// #endregion Global Imports

// #region Local Imports
// #endregion Local Imports

// #region Interface Imports
import { Templates } from "interfaces";
// #endregion Interface Imports

export type Props<T = ModalProps> = T & {
  setVisibleChoiceTemplate: (visible: boolean) => void;
};

export const TemplateChoiceModal: FC<Props> = ({ setVisibleChoiceTemplate, ...otherProps }) => {
  const { data: templatesRetrieveMany } =
    useList<Templates.TemplateItem>({
      resource: "templates", 
      config: {
        filters: [{
          field: "isActive",
          operator: "eq",
          value: true,
        }]
      }
    });

  // TODO: Cool (H)
  const { push } = useNavigation();

  const renderContent = () => {
    if (templatesRetrieveMany?.data) {
      return (
        <Row gutter={[16, 16]} justify="center">
          {templatesRetrieveMany.data.map((item) => (
            <Col key={item.id} md={6} style={{ textAlign: "center" }}>
              <img
                src={`/images/_templates/${item.resourceSlug}.jpg`}
                alt=""
                style={{
                  width: '100%',
                  marginBottom: 6,
                }}
              />
              <Button shape="round" type="primary" size="small" onClick={() => {
                setVisibleChoiceTemplate(false);
                push(`/contents/create?id=${item.id}`)
              }}>
                Devam Et
              </Button>
            </Col>
          ))}
        </Row>
      );
    }

    return null;
  };

  return (
    <>
      <Modal {...otherProps}>{renderContent()}</Modal>
    </>
  );
};
