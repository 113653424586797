import { Pie } from "@ant-design/charts";
import { Skeleton } from "@pankod/refine-antd";
import { IResourceComponentsProps, useCustom } from "@pankod/refine-core";
import React from "react";
import ENV from "utils/env";
import { useDashboard } from "dashboardProvider";
export const PlayedUnplayedBranchCountPie: React.FC<
  IResourceComponentsProps
> = () => {
  const { selectedDashboard } = useDashboard();

  const { data: response, isFetching } = useCustom({
    url: `${ENV.API_URL}/api/campaign/trivia/unplayedBranchDetails`,
    method: "get",
    config: {
      query: {
        dbkey: selectedDashboard,
      },
    },
  });

  if (isFetching) {
    return <Skeleton />;
  }

  let config = {
    data: [
      {
        type: "Oynanan Şube Sayısı",
        value: response?.data?.playedBranchesCount,
      },
      {
        type: "Oynanmayan Şube Sayısı",
        value: response?.data?.unPlayedBranchesCount,
      },
    ],
    angleField: "value",
    colorField: "type",
    paddingRight: 80,
    label: {
      text: "value",
      position: "outside",
    },
    legend: {
      color: {
        title: true,
        position: "left",
        rowPadding: 0,
      },
    },
  };

  return <Pie {...config} />;
};
