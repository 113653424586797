import { IResourceComponentsProps } from "@pankod/refine-core";

import {
  List,
  Table,
  Tag,
  useTable,
  EditButton,
  Icons,
  FilterDropdown,
  Typography,
  useModalForm,
  Radio,
} from "@pankod/refine-antd";

import dayjs from "dayjs";

import { QRColumn } from "./components/qr";
import { FilesCreate, FilesEdit } from "./";

import { Files } from "interfaces";

const { Title } = Typography;

export const FilesList: React.FC<IResourceComponentsProps> = () => {
  const { tableProps } = useTable<Files.FileItem>({
    resource: "file-manager/files",
  });

  const {
    formProps: filesCreateFormProps,
    modalProps: filesCreateModalProps,
    show: filesCreateModalShow,
  } = useModalForm({
    action: "create",
    resource: "file-manager/files",
    submitOnEnter: true,
    successNotification: {
      message: "Başarılı",
      description: "Dosya başarıyla yaratıldı",
      type: "success",
    },
  });

  const {
    formProps: filesEditFormProps,
    modalProps: filesEditModalProps,
    show: filesEditModalShow,
  } = useModalForm<Files.FileItem>({
    action: "edit",
    resource: "file-manager/files",
    submitOnEnter: true,
    successNotification: {
      message: "Başarılı",
      description: "Dosya başarıyla güncellendi",
      type: "success",
    },
  });


  return (
    <>
      <List
        title=""
        createButtonProps={{
          type: "primary",
          size: "large",
          shape: "round",
          icon: <Icons.UploadOutlined />,
          onClick: () => filesCreateModalShow(),
        }}
      >
        <Table {...tableProps} scroll={{ x: 1 }} rowKey="id">
          <Table.Column title="QR Kodu" dataIndex="id" render={QRColumn} />
          <Table.Column title="Başlık" dataIndex="title" />
          <Table.Column
            title="Oluşturma"
            dataIndex="createdAt"
            render={(value) => (
              <span>{dayjs(value).format("D MMMM YYYY HH:mm")}</span>
            )}
          />
          <Table.Column
            title="Son Güncelleme"
            dataIndex="updatedAt"
            render={(value) => (
              <span>{dayjs(value).format("D MMMM YYYY HH:mm")}</span>
            )}
          />
          <Table.Column<Files.FileItem>
            title="Durum"
            dataIndex="isActive"
            render={(_, record) => {
              if (record.isActive) {
                return <Tag color="success">Aktif</Tag>;
              }

              return <Tag color="red">Pasif</Tag>;
            }}
            filterDropdown={(props) => {
              return (
                <FilterDropdown {...props}>
                  <Title level={5}>Durum</Title>
                  <Radio.Group
                    options={[
                      { label: "Aktif", value: "1" },
                      { label: "Pasif", value: "0" },
                    ]}
                  />
                </FilterDropdown>
              );
            }}
          />
          <Table.Column<Files.FileItem>
            title="İşlemler"
            dataIndex="actions"
            render={(_, record) => (
              <EditButton
                size="small"
                onClick={() => {
                  filesEditModalShow(record.id);
                }}
              >
                Düzenle
              </EditButton>
            )}
          />
        </Table>
      </List>
      <FilesCreate
        formProps={filesCreateFormProps}
        modalProps={filesCreateModalProps}
      />
      <FilesEdit
        formProps={filesEditFormProps}
        modalProps={filesEditModalProps}
      />
    </>
  );
};
