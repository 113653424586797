import React from "react";
import { useCustom, usePermissions } from "@pankod/refine-core";
import {
  Tooltip,
  Modal,
  Card,
  Table,
  Result,
  Icons,
} from "@pankod/refine-antd";
import { IExternalDeviceSyncIsCompletedReducer, Roles } from "interfaces";
import { RoleTypes } from "interfaces/enums";
import ENV from "utils/env";

export const DeviceSyncWarningModal: React.FC = () => {
  const { data: userPermissions } = usePermissions<Roles.RoleItem>();

  const { data, isSuccess } =
    useCustom<IExternalDeviceSyncIsCompletedReducer.Data>({
      url: `${ENV.API_URL}/api/external/omma/device-sync-is-completed`,
      method: "get",
      queryOptions: {
        enabled: userPermissions?.type === RoleTypes.SHOP_MANAGER,
        retry: false,
      },
    });

  return (
    <Modal
      footer={null}
      visible={isSuccess && !data?.data.status}
      closable={false}
      width={800}
    >
      <Result
        status="warning"
        title="Poster özelliğini kullanabilmek için, lütfen bütün ekranlarınızda bulunan karekodları taratın ve hepsini kaydedin."
        subTitle="Mağazanızdaki tüm ekranları kaydettikten sonra bu uyarıyı görmeye devam ediyorsanız, lütfen daha sonra tekrar deneyin."
      />
      <Card title="Kaydedilmemiş cihazlar" style={{ marginBottom: "10px" }}>
        <Table dataSource={data?.data.notSynced} pagination={false}>
          <Table.Column dataIndex="name" title="İsim" />
          <Table.Column<IExternalDeviceSyncIsCompletedReducer.Device>
            dataIndex="isOnline"
            title="Durum"
            render={(_, record) => {
              const { isOnline } = record;
              return isOnline ? (
                <Tooltip title="Bağlantı başarılı">
                  <Icons.BulbOutlined style={{ color: "green" }} />
                </Tooltip>
              ) : (
                <>
                  <Tooltip title="Lütfen, ekranınızı ÇYM sistemine bildirin">
                    <Icons.InfoCircleOutlined style={{ color: "red" }} />
                  </Tooltip>
                </>
              );
            }}
          />
        </Table>
      </Card>
      <Card title="Kaydedilmiş cihazlar">
        <Table dataSource={data?.data.synced} pagination={false}>
          <Table.Column dataIndex="name" title="İsim" />
          <Table.Column<IExternalDeviceSyncIsCompletedReducer.Device>
            dataIndex="isOnline"
            title="Durum"
            render={(_, record) => {
              const { isOnline } = record;
              return isOnline ? (
                <Tooltip title="Bağlantı başarılı">
                  <Icons.BulbOutlined style={{ color: "green" }} />
                </Tooltip>
              ) : (
                <>
                  <Tooltip title="Lütfen, ekranınızı ÇYM sistemine bildirin">
                    <Icons.InfoCircleOutlined style={{ color: "red" }} />
                  </Tooltip>
                </>
              );
            }}
          />
        </Table>
      </Card>
    </Modal>
  );
};
