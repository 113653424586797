import routerProvider from "@pankod/refine-react-router-v6";
import "./style.scss";

const { Link } = routerProvider;
const logoUrl = "/images/turkcell-screen-logo.svg";
const shortLogoUrl = "/images/screen-logo.svg";

export const Title = ({ collapsed }) => (
  <div className="logo-container">
    <Link to="/">
      <img src={collapsed ? shortLogoUrl : logoUrl} alt="Turkcell" />
    </Link>
  </div>
);
