import { useCustom } from "@pankod/refine-core";

import { Card, Result } from "@pankod/refine-antd";
import ENV from "utils/env";

export const DeviceSync: React.FC = () => {
  const urlSearchParams = new URLSearchParams(window.location.search);
  const { deviceId, shopCode } = Object.fromEntries(urlSearchParams.entries());

  const { isSuccess, isFetching, isError, isIdle } = useCustom({
    url: `${ENV.API_URL}/api/external/omma/device-sync`,
    method: "post",
    config: {
      payload: {
        deviceId,
        shopCode,
      },
    },
    queryOptions: {
      enabled: deviceId && shopCode ? true : false,
      retry: false,
    },
  });

  const renderSuccess = () => (
    <Result
      status="success"
      title="Ekranınız başarıyla kaydedildi."
      subTitle="Mağazanızda karekod gözüken başka ekran varsa yaptığınız işlemi tekrarlayın."
    />
  );
  const renderFail = () => (
    <Result
      status="warning"
      title="Sistemde geçici bir sorun oluştu."
      subTitle="Lütfen daha sonra tekrar deneyin."
    />
  );
  const renderQrFail = () => (
    <Result status="warning" title="Lütfen karekodu tekrar okutun." />
  );

  return (
    <Card
      bodyStyle={{ minHeight: "500px", paddingTop: "100px" }}
      loading={isFetching}
    >
      {isIdle && renderQrFail()}
      {isSuccess && renderSuccess()}
      {isError && renderFail()}
    </Card>
  );
};
