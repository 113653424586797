import { Column } from "@ant-design/charts";
import { useDashboard } from "dashboardProvider";
import { Skeleton } from "@pankod/refine-antd";
import { IResourceComponentsProps, useCustom } from "@pankod/refine-core";
import { CitySelector } from "components/CitySelector/cityselector";
import dayjs from "dayjs";

import React from "react";
import ENV from "utils/env";

export const AttendedCountBar: React.FC<IResourceComponentsProps> = () => {
  const [selectedCity, setSelectedCity] = React.useState<string | null>(null);
  const { selectedDashboard } = useDashboard();

  const {
    data: response,
    isFetching,
    isError,
  } = useCustom({
    url: `${ENV.API_URL}/api/campaign/lottery/mostAttendedDays?dbkey=${selectedDashboard}`,
    method: "post",
    queryOptions: {
      retry: 0,
      enabled: true,
    },
    config: {
      payload: {
        startDate: null,
        endDate: null,
        city: selectedCity ? selectedCity : null,
      },
    },
  });
  if (isFetching) {
    return <Skeleton />;
  }

  if (isError) {
    return (
      <div>
        <CitySelector
          selectedCity={selectedCity}
          setSelectedCity={setSelectedCity}
        ></CitySelector>
        <div
          style={{ display: "flex", justifyContent: "center", marginTop: 16 }}
        >
          <span style={{ fontWeight: 800, fontSize: 20 }}>
            Bu filtrelere ait veri bulunamadı.
          </span>
        </div>
      </div>
    );
  }

  const daysOfWeek = [
    "Pazar",
    "Pazartesi",
    "Salı",
    "Çarşamba",
    "Perşembe",
    "Cuma",
    "Cumartesi",
  ];

  const dayOrder = {
    Pazar: 0,
    Pazartesi: 1,
    Salı: 2,
    Çarşamba: 3,
    Perşembe: 4,
    Cuma: 5,
    Cumartesi: 6,
  };

  const data = response.data.days.reduce((acc, i) => {
    const dayIndex = dayjs(i.day).day();
    const dayName = daysOfWeek[dayIndex];

    const existingDay = acc.find((dayObj) => dayObj.day === dayName);

    if (existingDay) {
      existingDay.value += Number(i.game_count);
    } else {
      acc.push({ day: dayName, value: Number(i.game_count) });
    }

    return acc;
  }, []);

  // Sort the data by day order
  data.sort((a, b) => dayOrder[a.day] - dayOrder[b.day]);

  const config = {
    data,
    xField: "day",
    yField: "value",
    label: {
      text: (originData) => {
        return "";
      },
      offset: 10,
    },
    legend: false,
  };

  return (
    <div>
      <CitySelector
        selectedCity={selectedCity}
        setSelectedCity={setSelectedCity}
      ></CitySelector>
      <Column {...config} style={{ maxWidth: 100 }} />
    </div>
  );
};
