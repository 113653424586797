
export enum RoleTypes {
    SUPER_ADMIN = "super-admin",
    SYSTEM_ADMIN = "system-admin",
    SALES_MANAGER = "sales-manager",
    SHOP_MANAGER = "shop-manager",
    ASSET_MANAGER = "asset-manager",
    REPORTER = "reporter",
}

export enum TagTypes {
    TEMPLATE = "template",
    ASSET = "asset",
}

export enum ContentStatus {
    WAITING_APPROVAL = "waiting_approval",
    PUBLISHED = "published",
    REJECTED = "rejected",
    STOPPED = "stopped",
    DONE = "done",
}
