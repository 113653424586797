import { Col, Row, Skeleton, Table } from "@pankod/refine-antd";
import { IResourceComponentsProps, useCustom } from "@pankod/refine-core";
import DownloadMenu from "components/DownloadDropdown";
import dayjs from "dayjs";
import ENV from "utils/env";
import {
  exportToCsv,
  exportToExcel,
  exportToJson,
} from "utils/utiltyFunctions";
import { useDashboard } from "dashboardProvider";

const secondTabColumns = [
  {
    header: "Mevcut Tim Kodu",
    key: "key",
  },
  {
    header: "Turkcell Adı",
    key: "name",
  },
];

export const TableUnattendedBranchDetails: React.FC<
  IResourceComponentsProps
> = () => {
  const { selectedDashboard } = useDashboard();

  const {
    data: response,
    isFetching,
    isError,
  } = useCustom({
    url: `${ENV.API_URL}/api/campaign/lottery/unAttendedBranchDetails`,
    method: "get",
    config: {
      query: {
        dbkey: selectedDashboard,
      },
    },
  });

  if (isFetching) {
    return <Skeleton />;
  }
  if (isError) {
    return (
      <span style={{ fontWeight: 800, fontSize: 20 }}>Veri bulunamadı.</span>
    );
  }

  const dataSource = response.data.branchDetails.map((item: any) => {
    return {
      key: item["shopCode"],
      name: item["name"],
      salesManager: item["salesManager"] ? item["salesManager"] : "-",
      areaManager: item["areaManager"] ? item["areaManager"] : "-",
      city: item["city"] ? item["city"] : "-",
      area: item["area"] ? item["area"] : "-",
    };
  });

  const uniqueAreas = Array.from(new Set(dataSource.map((item) => item.area)))
    .map((area) => ({ text: area as string, value: area as string }))
    .sort((a, b) => a.text.localeCompare(b.text));

  const uniqueCities = Array.from(new Set(dataSource.map((item) => item.city)))
    .map((city) => ({ text: city as string, value: city as string }))
    .sort((a, b) => a.text.localeCompare(b.text));

  const columns = [
    {
      title: "Mevcut Tim Kodu",
      dataIndex: "key",
      key: "key",
      width: "25%",
    },
    {
      title: "Turkcell Adı",
      dataIndex: "name",
      key: "name",
      width: "25%",
    },
    {
      title: "Satış Müdürü",
      dataIndex: "salesManager",
      key: "salesManager",
      width: "25%",
    },
    {
      title: "Bölge Müdürü",
      dataIndex: "areaManager",
      key: "areaManager",
      width: "25%",
    },
    {
      title: "Şehir",
      dataIndex: "city",
      key: "city",
      width: "25%",
      filters: uniqueCities, // Benzersiz şehirleri filtre olarak ekle
      onFilter: (value: string, record: any) => record.city === value, // Filtreleme işlevi
    },
    {
      title: "Bölge",
      dataIndex: "area",
      key: "area",
      width: "25%",
      filters: uniqueAreas, // Benzersiz bölgeleri filtre olarak ekle
      onFilter: (value: string, record: any) => record.area === value, // Filtreleme işlevi
    },
  ];

  const updatedAt = response.data.updatedAt;
  const parsedUpdatedAt = dayjs(updatedAt).format("DD/MM/YYYY HH:mm");
  const formattedDate = updatedAt
    ? dayjs(updatedAt).format("DD-MM-YYYY-HH-mm")
    : "";

  return (
    <div>
      <Row justify="end" gutter={16}>
        <Col>
          <DownloadMenu
            dataSource={dataSource}
            columns={secondTabColumns}
            formattedDate={formattedDate}
            exportToExcel={exportToExcel}
            exportToCsv={exportToCsv}
            exportToJson={exportToJson}
          />
        </Col>
      </Row>
      <Row>
        <Col span={6}>Katılım Sağlanan Bayi Sayısı</Col>
        <Col span={6}>
          <b>{response.data.playedBranchesCount}</b>
        </Col>
        <Col span={12} />
      </Row>
      <Row>
        <Col span={6}>Katılım Sağlanmayan Bayi Sayısı</Col>
        <Col span={6}>
          <b>{response.data.unPlayedBranchesCount}</b>
        </Col>
        <Col span={12} />
      </Row>
      <Row>
        <Col span={6}>Son Güncelleme Tarihi:</Col>
        <Col span={6}>
          {dayjs(response.data.updatedAt).format("DD/MM/YYYY HH:mm")}
        </Col>
        <Col span={6} style={{ fontWeight: 600 }}>
          Veriler her 5 dakikada bir güncellenmektedir.
        </Col>
        <Col span={12} />
      </Row>
      <Table
        dataSource={dataSource}
        columns={columns}
        pagination={{ pageSize: 25, disabled: false }}
        scroll={{ x: 1500 }}
      />
    </div>
  );
};
