// #region Global Imports
import { FC } from "react";
import { Form, Input, Select, InputNumber, Slider, Checkbox } from "antd";
// #endregion Global Imports

// #region Local Imports
import { Asset } from "./components";
// #region Local Imports

// #region Interface Imports
import { Templates } from "interfaces";
// #endregion Interface Imports

type Props = {
  parameters: Templates.TemplateParameters[];
  triggerChange: () => void;
  filterFields?: [string];
  tooltip?: string;
};

export const FormBuilder: FC<Props> = ({
  triggerChange,
  parameters,
  filterFields,
  tooltip,
}) => {
  const renderItem = (item: Templates.TemplateParameters) => {
    switch (item.type) {
      case "asset":
        return <Asset onChange={triggerChange} />;

      case "input":
        return <Input {...item.elementAttr} onBlur={triggerChange} />;

      case "input_price":
        return (
          <InputNumber
            formatter={(value) =>
              `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ".")
            }
            parser={(value = "") => value.replace(/(\.*)/g, "")}
            onBlur={triggerChange}
            {...item.elementAttr}
          />
        );

      case "select":
        return <Select onChange={triggerChange} options={item.options} />;
      case "slider":
        return (
          <Slider
            onChange={triggerChange}
            style={{ width: "100%" }}
            min={-1}
            max={1}
            step={0.01}
            {...item.elementAttr}
          />
        );

      case "checkbox_group":
        return (
          <Checkbox.Group
            onChange={triggerChange}
            options={item.options}
            {...item.elementAttr}
          />
        );

      default:
        return null;
    }
  };

  const formItem = (item: Templates.TemplateParameters) => (
    <Form.Item
      key={item.name}
      label={item.label}
      name={`parameters.${item.name}`}
      rules={item.rules}
      tooltip={tooltip}
    >
      {renderItem(item)}
    </Form.Item>
  );

  const findTourSelector = (filterFields: string[]): string => {
    switch (filterFields[0]) {
      case "priceBounceHeight":
        return "step-priceBounceHeight";
      case "installmentBounceHeight":
        return "step-installmentBounceHeight";
      case "percentBounceHeight":
        return "step-percentBounceHeight";
      case "giftTextBounceHeight":
        return "step-giftTextBounceHeight";
      default:
        return "";
    }
  };

  if (filterFields) {
    return (
      <div data-tour={`${findTourSelector(filterFields)}`}>
        {parameters.map((item) => {
          if (filterFields.includes(item.name)) {
            return formItem(item);
          }
        })}
      </div>
    );
  }

  return <div>{parameters.map((item) => formItem(item))}</div>;
};
