import { Col, Row, Skeleton, Table } from "@pankod/refine-antd";
import { IResourceComponentsProps, useCustom } from "@pankod/refine-core";
import DownloadMenu from "components/DownloadDropdown";
import dayjs from "dayjs";
import ENV from "utils/env";
import {
  exportToCsv,
  exportToExcel,
  exportToJson,
} from "utils/utiltyFunctions";
import { useDashboard } from "dashboardProvider";
export const TableTopUsers: React.FC<IResourceComponentsProps> = () => {
  const { selectedDashboard } = useDashboard();

  const { data: response, isFetching } = useCustom({
    url: `${ENV.API_URL}/api/campaign/trivia/topScoredUsers`,
    method: "get",
    config: {
      query: {
        dbkey: selectedDashboard,
      },
    },
  });
  if (isFetching) {
    return <Skeleton />;
  }

  const dataSource = response.data.users.map((item: any) => {
    return {
      key: item["gsmNumber"],
      gsmNumber: item["gsmNumber"],
      score: item["score"],
      played_date: dayjs(item["createdAt"]).format("DD/MM/YYYY HH:mm"),
      shopInfo: item.shopInfo["name"],
    };
  });

  const thirdTabColumns = [
    {
      title: "Telefon Numarası",
      dataIndex: "gsmNumber",
      key: "gsmNumber",
      width: "25%",
    },
    {
      title: "Skor",
      dataIndex: "score",
      key: "score",
    },
    {
      title: "Oynanma Tarihi",
      dataIndex: "played_date",
      key: "played_date",
    },
    { title: "Oynandığı Mağaza", dataIndex: "shopInfo", key: "shopInfo" },
  ];

  const updatedAt = response.data.updatedAt;
  const parsedUpdatedAt = dayjs(updatedAt).format("DD/MM/YYYY HH:mm");
  const formattedDate = updatedAt
    ? dayjs(updatedAt).format("DD-MM-YYYY-HH-mm")
    : "";

  return (
    <div>
      <Row justify="end" gutter={16}>
        <Col>
          <DownloadMenu
            dataSource={dataSource}
            columns={thirdTabColumns}
            formattedDate={formattedDate}
            exportToExcel={exportToExcel}
            exportToCsv={exportToCsv}
            exportToJson={exportToJson}
          />
        </Col>
      </Row>
      <Row>
        <Col span={6}>Son Güncelleme Tarihi:</Col>
        <Col span={6}>
          {dayjs(response.data.updatedAt).format("DD/MM/YYYY HH:mm")}
        </Col>
        <Col span={12} />
      </Row>
      <Table
        dataSource={dataSource}
        columns={thirdTabColumns}
        pagination={{ pageSize: 25, disabled: false }}
        scroll={{ x: 1500 }}
      />
    </div>
  );
};
