// #region Global Imports
import React, { FC } from "react";
import { useCustom } from "@pankod/refine-core";
import { Button } from "@pankod/refine-antd";
// #endregion Global Imports

// #region Interface Imports
import { Contents, Templates } from "interfaces";
import ENV from "utils/env";
// #endregion Interface Imports

type Props = {
  template: Templates.TemplateItem;
  parameters: Contents.ContentParameters[];
};

export const ContentPreview: FC<Props> = ({ template, parameters }) => {
  const containerRef = React.useRef<HTMLDivElement>(null);
  const [width, setWidth] = React.useState(0);

  const [isAnimationsPaused, setIsAnimationsPaused] = React.useState(true);
  const [playingVideo, setPlayingVideo] = React.useState(false);

  const playVideo = () => {
    setPlayingVideo(true);
    setIsAnimationsPaused(false);
  };

  const stopVideo = () => {
    setPlayingVideo(false);
    setIsAnimationsPaused(true);
  };

  const { data: templatesPreviewTemplate, isFetching } = useCustom<{
    html: string;
  }>({
    url: `${ENV.API_URL}/api/templates/preview-data`,
    method: "get",
  });

  React.useEffect(() => {
    const handler = (event: any) => {
      const message =
        typeof event.data === "string" ? JSON.parse(event.data) : event.data;
      if (message && message.status === "onFinished") {
        stopVideo();
      }
    };

    window.addEventListener("message", handler);

    return () => window.removeEventListener("message", handler);
  }, []);

  React.useEffect(() => {
    const handleResize = () => {
      if (containerRef.current) {
        setWidth(containerRef.current.offsetWidth);
      }
    };

    handleResize();

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [isFetching]);

  const renderIframe = () => {
    if (isFetching) {
      return <span>Loading..</span>;
    }

    if (templatesPreviewTemplate?.data?.html) {
      const html = templatesPreviewTemplate.data.html.replace(
        "___SCRIPT___",
        `
                <script type="text/javascript">
                    window.template = ${JSON.stringify(template.resourceSlug)};
                </script>
                <script type="text/javascript">
                    window.parameters = ${JSON.stringify([
                      ...parameters,
                      {
                        name: "isAnimationsPaused",
                        value: `${isAnimationsPaused}`,
                      },
                    ])};
                </script>
                `
      );

      return (
        <>
          <div
            ref={containerRef}
            style={{
              height: width * 1.77,
              overflow: "hidden",
            }}
          >
            <iframe
              style={{
                border: "none",
                width: "1080px",
                height: "1920px",
                transform: `scale(${width / 1080})`,
                transformOrigin: "top left",
              }}
              title="Preview Template"
              srcDoc={html}
            />
          </div>
          <div
            style={{ textAlign: "center", marginTop: 12 }}
            data-tour="step-5"
          >
            <Button
              onClick={playVideo}
              disabled={playingVideo}
              type="primary"
              shape="round"
            >
              {playingVideo ? "Video Oynatılıyor" : "Video Oynat"}
            </Button>
          </div>
        </>
      );
    }

    return null;
  };

  return renderIframe();
};
