import { Button, Col, Input, Row, Skeleton, Table } from "@pankod/refine-antd";
import { IResourceComponentsProps, useCustom } from "@pankod/refine-core";
import DownloadMenu from "components/DownloadDropdown";
import dayjs from "dayjs";
import React, { useState } from "react";
import ENV from "utils/env";
import {
  exportToCsv,
  exportToExcel,
  exportToJson,
} from "utils/utiltyFunctions";
import { useDashboard } from "dashboardProvider";

export const TableDailyAttendedBranchesCount: React.FC<
  IResourceComponentsProps
> = () => {
  const { selectedDashboard } = useDashboard();
  const {
    data: response,
    isFetching,
    isError,
  } = useCustom({
    url: `${ENV.API_URL}/api/campaign/lottery/dailyAttendedBranches`,
    method: "get",
    config: {
      query: {
        dbkey: selectedDashboard,
      },
    },
  });

  const [searchText, setSearchText] = useState<string>("");

  if (isFetching) {
    return <Skeleton />;
  }
  if (isError) {
    return (
      <span style={{ fontWeight: 800, fontSize: 20 }}>Veri bulunamadı.</span>
    );
  }

  let dataSource = response.data?.branches.map((i) => {
    return {
      key: i.shopInfo.name,
      name: i.shopInfo.name,
      count: Number(i.count),
    };
  });

  const filteredDataSource = dataSource?.filter((item) =>
    item.name.toLowerCase().includes(searchText.toLowerCase())
  );

  const thirdTabColumns = [
    {
      title: "Bayi Adı",
      dataIndex: "name",
      key: "name",
      width: "25%",
      filterDropdown: ({ setSelectedKeys, selectedKeys, confirm }) => (
        <div style={{ padding: 8 }}>
          <Input
            onKeyPress={(e) => {
              if (e.key === "Enter") {
                confirm();
              }
            }}
            type="text"
            placeholder="Ara"
            value={selectedKeys[0]}
            onChange={(e) => {
              setSelectedKeys(e.target.value ? [e.target.value] : []);
            }}
            style={{ width: 188, marginBottom: 8, display: "block" }}
          />
          <Button onClick={confirm} style={{ width: 90, marginRight: 8 }}>
            Ara
          </Button>
        </div>
      ),
      onFilter: (value, record) =>
        record.name.toLowerCase().includes(value.toLowerCase()),
    },
    {
      title: "Sayı",
      dataIndex: "count",
      key: "count",
      width: "25%",
    },
  ];

  const updatedAt = response.data?.updatedAt;
  const formattedDate = updatedAt
    ? dayjs(updatedAt).format("DD-MM-YYYY-HH-mm")
    : "";

  return (
    <div>
      <Row justify="end" gutter={16}>
        <Col>
          <DownloadMenu
            dataSource={filteredDataSource}
            columns={thirdTabColumns}
            formattedDate={formattedDate}
            exportToExcel={exportToExcel}
            exportToCsv={exportToCsv}
            exportToJson={exportToJson}
          />
        </Col>
      </Row>
      <Row>
        <Col span={6}>Son Güncelleme Tarihi:</Col>
        <Col span={6}>
          {dayjs(response.data.updatedAt).format("DD/MM/YYYY HH:mm")}
        </Col>
        <Col span={12} />
      </Row>
      <Table
        dataSource={filteredDataSource}
        columns={thirdTabColumns}
        pagination={{ pageSize: 25, disabled: false }}
        scroll={{ x: 1500 }}
      />
    </div>
  );
};
