import {
  Refine,
  HttpError,
  Authenticated,
  LayoutWrapper,
} from "@pankod/refine-core";
import {
  Icons,
  AntdLayout,
  notificationProvider,
  ErrorComponent,
} from "@pankod/refine-antd";
import routerProvider from "@pankod/refine-react-router-v6";
import dataProvider from "@pankod/refine-nestjsx-crud";
import { useTranslation } from "react-i18next";
import dayjs from "dayjs";
import "dayjs/locale/tr";

import { authProvider, axios } from "authProvider";
import { Login } from "pages/login";
import { DashboardPage } from "pages/dashboard";
import { Header, Layout, Title } from "components/layout";
import { UserList } from "pages/users";
import { ModerationList } from "pages/moderation";
import { ContentsList, ContentCreate, ContentEdit } from "pages/contents";
import {
  CampaignCreate,
  CampaignEdit,
  CampaignsList,
} from "pages/campaign-management";
import { AssetsList } from "pages/assets";
import { FilesList } from "pages/files";
import { TagsList } from "pages/tags";
import { DeviceSync } from "pages/devicesync";
import { TriviaDashboard } from "pages/campaigns/trivia";

import "styles/antd.less";
import "styles/main.scss";
import ENV from "utils/env";
import { DashboardProvider } from "dashboardProvider";
import { LotteryDashboard } from "pages/campaigns/lottery/dashboard";
import { AppInformationList } from "pages/campaign-information/list";
var localizedFormat = require("dayjs/plugin/localizedFormat");

dayjs.locale("tr");
dayjs.extend(localizedFormat);

axios.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    const customError: HttpError = {
      ...error,
      message: error.response?.data?.message,
      statusCode: error.response?.status,
    };

    return Promise.reject(customError);
  }
);

function App() {
  const API_URL = `${ENV.API_URL}/api`;

  const { t, i18n } = useTranslation();

  const i18nProvider = {
    translate: (key: string, params: object) => t(key, params),
    changeLocale: (lang: string) => i18n.changeLanguage(lang),
    getLocale: () => i18n.language,
  };

  const nestJSDataProvider = dataProvider(API_URL, axios);
  const myDataProvider = {
    ...nestJSDataProvider,
    getMany: async ({ resource, ids }: { resource: string; ids: string[] }) => {
      const { data } = await nestJSDataProvider.getMany({
        resource,
        ids,
      });

      return data as any;
    },
  };

  return (
    <DashboardProvider>
      <Refine
        dataProvider={myDataProvider}
        authProvider={authProvider}
        i18nProvider={i18nProvider}
        LoginPage={Login}
        Title={Title}
        Layout={Layout}
        DashboardPage={DashboardPage}
        routerProvider={{
          ...routerProvider,

          routes: [
            {
              element: (
                <Authenticated>
                  <LayoutWrapper
                    Layout={({ OffLayoutArea, children }) => (
                      <AntdLayout
                        style={{ minHeight: "100vh", flexDirection: "row" }}
                      >
                        <AntdLayout>
                          {Header && <Header />}
                          <AntdLayout.Content>
                            <div style={{ padding: 24, minHeight: 360 }}>
                              {children}
                            </div>
                            {OffLayoutArea && <OffLayoutArea />}
                          </AntdLayout.Content>
                        </AntdLayout>
                      </AntdLayout>
                    )}
                  >
                    <DeviceSync />
                  </LayoutWrapper>
                </Authenticated>
              ),
              path: "/devicesync",
            },
          ],
        }}
        resources={[
          {
            name: "users",
            icon: <Icons.UsergroupAddOutlined />,
            list: UserList,
          },
          {
            name: "contents",
            icon: <Icons.EyeOutlined />,
            list: ModerationList,
            options: {
              route: "contents/review",
              label: "Moderasyon",
            },
          },
          {
            name: "contents",
            icon: <Icons.FormOutlined />,
            list: ContentsList,
            create: ContentCreate,
            edit: ContentEdit,
          },
          {
            name: "assets",
            icon: <Icons.UploadOutlined />,
            list: AssetsList,
          },
          {
            name: "files",
            icon: <Icons.FilePdfOutlined />,
            list: FilesList,
          },
          {
            name: "tags",
            icon: <Icons.TagOutlined />,
            list: TagsList,
          },
          {
            name: "trivia",
            icon: <Icons.TrophyOutlined />,
            list: TriviaDashboard,
            options: {
              label: "Bilgi Yarışmaları",
            },
          },
          {
            name: "lottery",
            icon: <Icons.GiftOutlined />,
            list: LotteryDashboard,
            options: {
              label: "Çekilişler",
            },
          },
          {
            name: "campaign",
            icon: <Icons.AimOutlined />,

            list: CampaignsList,
            edit: CampaignEdit,
            create: CampaignCreate,

            options: {
              label: "Kampanya Yönetimi",
              route: "campaign-management",
            },
          },
          {
            name: "app-information",
            icon: <Icons.InfoCircleOutlined />,

            list: AppInformationList,
            options: {
              label: "Uygulama Bilgileri",
              route: "app-information",
            },
          },
        ]}
        notificationProvider={notificationProvider}
        catchAll={<ErrorComponent />}
      />
    </DashboardProvider>
  );
}

export default App;
