// #region Global Imports
import { FC, useEffect, useState } from "react";
import { useList, CrudFilters, HttpError } from "@pankod/refine-core";

import {
    Modal as AntModal,
    Skeleton,
    Tag,
    Input,
    ModalProps,
    useSimpleList,
    AntdList,
    Row,
    Col,
    Form,
} from "@pankod/refine-antd";

import classNames from "classnames";
import debounce from "lodash/debounce";
// #endregion Global Imports

// #region Local Imports
import { TagTypes } from "interfaces/enums";
import { Item } from "../index";
// #region Local Imports

// #region Interface Imports
import { SelectAsset } from "../../IAsset";
import { Assets, Tags } from "interfaces";
// #endregion Interface Imports

type Props<T = ModalProps> = T & {
    selectedItem?: SelectAsset;
    onClickItem: (asset: SelectAsset) => void;
};

export const Modal: FC<Props> = ({
    selectedItem,
    onClickItem,
    ...otherProps
}) => {

    const [filterTags, setFilterTags] = useState<string[]>([]);




    const { listProps: assetsListProps, searchFormProps } = useSimpleList<Assets.AssetItem, HttpError, { name: string }>({
        resource: "assets",
        pagination: {
            pageSize: 24,
        },
        onSearch: (params) => {
            const { name } = params;
            const assetFilters: CrudFilters = [];

            assetFilters.push({
                field: "tags.id",
                operator: "in",
                value: filterTags.length > 0 ? filterTags : undefined,
            });

            assetFilters.push({
                field: "name",
                operator: "contains",
                value: name?.length > 0 ? name : undefined,
            });

            return assetFilters;
        }
    });

    const { data: tagsRetrieveMany, isFetching } = useList<Tags.TagItem>({
        resource: "tags",
        config: {

            filters: [
                {
                    field: "type",
                    operator: "eq",
                    value: TagTypes.ASSET
                },
                {
                    field: "isActive",
                    operator: "eq",
                    value: true,
                },
            ],
            sort: [{ field: "name", order: "asc" }],
            pagination: {
                pageSize: 200,
            }
        }
    });

    const toggleFilterTag = (clicked: string) => {
        const target = filterTags.findIndex((tag) => tag === clicked);

        if (target < 0) {
            setFilterTags((currentTags) => {
                return [...currentTags, clicked];
            });
        } else {
            const copyFilterTags = [...filterTags];

            copyFilterTags.splice(target, 1);

            setFilterTags(copyFilterTags);
        }


    };

    const renderTags = () => {
        if (isFetching) {
            return <Skeleton />;
        }

        if (tagsRetrieveMany?.data) {
            return (
                <div style={{ height: "600px", overflowY: "scroll" }}>
                    <Tag
                        color={filterTags.length ? "default" : "blue"}
                        onClick={() => setFilterTags([])}
                        style={{ marginBottom: 8, cursor: "pointer" }}
                    >
                        Tüm Etiketler
                    </Tag>

                    {tagsRetrieveMany.data.map((tag) => (
                        <Tag
                            key={tag.id}
                            onClick={() => toggleFilterTag(tag.id!)}
                            color={
                                filterTags.includes(tag.id!) ? "blue" : "default"
                            }
                            style={{ marginBottom: 8, cursor: "pointer" }}

                        >
                            {tag.name}
                        </Tag>
                    ))}
                </div>
            );
        }

        return null;
    };

    const renderAssets = () => {
        return (
            <AntdList {...assetsListProps} rowKey="id" grid={{ gutter: 8, column: 3 }} renderItem={(item) => (
                <Item
                    className={classNames({
                        active:
                            selectedItem &&
                            selectedItem.path === item.path,
                    })}
                    onClick={() => onClickItem(item)}
                    image={item.path}
                    name={item.name}
                />
            )} />
        );
    }


    const formOnValuesChange = debounce(
        () => searchFormProps.form?.submit(),
        300
    );

    useEffect(() => {
        searchFormProps.form?.submit();
    }, [filterTags])

    return (
        <AntModal {...otherProps} width={1200}>
            <Form
                {...searchFormProps}
                layout="vertical"
                onValuesChange={formOnValuesChange}
            >
                <Row gutter={[16, 16]}>
                    <Col span={16}>
                        <Form.Item label="" name="name" noStyle={true}>
                            <Input
                                placeholder="Ürün Ara"
                                className="mb-3"
                                allowClear
                            />
                        </Form.Item>
                        {/*  <Spin
                        spinning={isLoading(assetsRetrieveMany.status)} 
                        size="large"
                    > */}
                        <div
                            className="row"
                            style={{ height: 600, overflowX: "scroll" }}
                        >
                            {renderAssets()}
                        </div>
                        {/*  </Spin> */}
                    </Col>
                    <Col span={8}>
                        <p>
                            Aşağıdaki etiketleri kullanarak aramanızı
                            daraltabilirsiniz:
                        </p>
                        {renderTags()}
                    </Col>
                </Row>
            </Form>
        </AntModal>
    );
};
