import { IResourceComponentsProps } from "@pankod/refine-core";

import {
  List,
  Table,
  Tag,
  useTable,
  EditButton,
  Icons,
  FilterDropdown,
  Checkbox,
  useCheckboxGroup,
  Typography,
  useSelect,
  Select,
  Radio,
  useModalForm,
} from "@pankod/refine-antd";

import { UserCreate, UserEdit } from "./";

import { Roles, Users } from "interfaces";

const { Title } = Typography;

export const UserList: React.FC<IResourceComponentsProps> = () => {
  const { tableProps } = useTable<Users.UserItem>();

  const { checkboxGroupProps } = useCheckboxGroup<Roles.RoleItem>({
    resource: "roles",
    optionLabel: "name",
    optionValue: "id",
  });

  const { selectProps: userFilterSelectProps } = useSelect<Users.UserItem>({
    resource: "users",
    optionLabel: "fullName",
    optionValue: "id",
  });

  const { selectProps: ommaShopsFilterSelectProps } = useSelect<any>({
    fetchSize: 5000,
    resource: "omma-shops",
    optionLabel: "name",
    optionValue: "id",
    filters: [{ field: "isActive", operator: "eq", value: true }],
  });

  const {
    formProps: userCreateFormProps,
    modalProps: userCreateModalProps,
    show: userCreateModalShow,
  } = useModalForm({
    action: "create",
    successNotification: {
      message: "Başarılı",
      description: "Kullanıcı başarıyla yaratıldı",
      type: "success",
    },
  });

  const {
    formProps: userEditFormProps,
    modalProps: userEditModalProps,
    show: userEditModalShow,
    queryResult: userEditQueryResult,
  } = useModalForm<Users.UserItem>({
    action: "edit",
    successNotification: {
      message: "Başarılı",
      description: "Kullanıcı başarıyla güncellendi",
      type: "success",
    },
  });

  return (
    <>
      <List
        createButtonProps={{
          type: "primary",
          size: "large",
          shape: "round",
          icon: <Icons.UserAddOutlined />,
          onClick: () => userCreateModalShow(),
        }}
        title="Kullanıcılar"
      >
        <Table {...tableProps} rowKey="id" scroll={{ x: 1 }}>
          <Table.Column
            key={"role.id"}
            dataIndex={["role", "name"]}
            title="Rol"
            filterDropdown={(props) => {
              return (
                <FilterDropdown {...props}>
                  <Title level={5}>Rol Seçin</Title>
                  <Checkbox.Group
                    style={{ width: 200 }}
                    {...checkboxGroupProps}
                  />
                </FilterDropdown>
              );
            }}
          />
          <Table.Column
            dataIndex="fullName"
            title="İsim"
            key="id"
            filterDropdown={(props) => {
              return (
                <FilterDropdown {...props}>
                  <Title level={5}>Aramak istediğiniz ismi yazın</Title>
                  <Select
                    {...userFilterSelectProps}
                    style={{ width: "100%" }}
                  />
                </FilterDropdown>
              );
            }}
          />
          <Table.Column<Users.UserItem>
            dataIndex="shops"
            title="Bağlı Mağazalar"
            key="ommaShops.id"
            render={(_, record) => {
              const { ommaShops } = record;
              const shopsCount = ommaShops.length;

              if (shopsCount) {
                const showShopCount = 3;
                return (
                  <>
                    {ommaShops.map((shop: any, index: number) => {
                      if (index < showShopCount) {
                        return (
                          <Tag className="mb-1" key={shop.id}>
                            {shop.name}
                          </Tag>
                        );
                      }
                      return false;
                    })}
                    {shopsCount > showShopCount && (
                      <Tag className="mb-1" key="more">
                        {`${shopsCount - showShopCount} Mağaza daha`}
                      </Tag>
                    )}
                  </>
                );
              }

              return <span>--</span>;
            }}
            filterDropdown={(props) => {
              return (
                <FilterDropdown {...props}>
                  <Title level={5}>Aramak istediğiniz mağazayı yazın</Title>
                  <Select
                    {...ommaShopsFilterSelectProps}
                    style={{ width: "100%" }}
                    mode="multiple"
                  />
                </FilterDropdown>
              );
            }}
          />

          <Table.Column<Users.UserItem>
            dataIndex="isActive"
            title="Durum"
            render={(_, record) => {
              if (record.isActive) {
                return <Tag color="success">Aktif</Tag>;
              }

              return <Tag color="red">Pasif</Tag>;
            }}
            filterDropdown={(props) => {
              return (
                <FilterDropdown {...props}>
                  <Title level={5}>Kullanıcı durumunu seçin</Title>
                  <Radio.Group
                    options={[
                      { label: "Aktif Kullanıcılar", value: "1" },
                      { label: "Pasif Kullanıcılar", value: "0" },
                    ]}
                  />
                </FilterDropdown>
              );
            }}
          />
          <Table.Column<Users.UserItem>
            title="İşlemler"
            dataIndex="actions"
            render={(_, record) => (
              <EditButton
                size="small"
                onClick={() => userEditModalShow(record.id)}
              >
                Düzenle
              </EditButton>
            )}
          />
        </Table>
      </List>
      <UserCreate
        formProps={userCreateFormProps}
        modalProps={userCreateModalProps}
      />

      <UserEdit
        formProps={userEditFormProps}
        modalProps={userEditModalProps}
        queryResult={userEditQueryResult}
      />
    </>
  );
};
