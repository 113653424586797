import { Col, Row, Skeleton, Table } from "@pankod/refine-antd";
import { IResourceComponentsProps, useCustom } from "@pankod/refine-core";
import DownloadMenu from "components/DownloadDropdown";
import dayjs from "dayjs";
import ENV from "utils/env";
import {
  exportToCsv,
  exportToExcel,
  exportToJson,
} from "utils/utiltyFunctions";
import { useDashboard } from "dashboardProvider";
export const TableMostPlayedBranchDetails: React.FC<
  IResourceComponentsProps
> = () => {
  const { selectedDashboard } = useDashboard();

  const { data: response, isFetching } = useCustom({
    url: `${ENV.API_URL}/api/campaign/trivia/mostPlayedBranchDetails`,
    method: "get",
    config: {
      query: {
        dbkey: selectedDashboard,
      },
    },
  });
  if (isFetching) {
    return <Skeleton />;
  }

  const dataSource = response.data.filteredBranches.map((item: any) => {
    return {
      key: item["shopCode"],
      name: item["name"],
      count: item["count"],
    };
  });

  const thirdTabColumns = [
    {
      title: "Mevcut Tim Kodu",
      dataIndex: "key",
      key: "key",
      width: "25%",
    },
    {
      title: "Turkcell Adı",
      dataIndex: "name",
      key: "name",
      width: "25%",
    },
    {
      title: "Sayı",
      dataIndex: "count",
      key: "count",
    },
  ];
  const updatedAt = response.data.updatedAt;
  const parsedUpdatedAt = dayjs(updatedAt).format("DD/MM/YYYY HH:mm");
  const formattedDate = updatedAt
    ? dayjs(updatedAt).format("DD-MM-YYYY-HH-mm")
    : "";

  return (
    <div>
      <Row justify="end" gutter={16}>
        <Col>
          <DownloadMenu
            dataSource={dataSource}
            columns={thirdTabColumns}
            formattedDate={formattedDate}
            exportToExcel={exportToExcel}
            exportToCsv={exportToCsv}
            exportToJson={exportToJson}
          />
        </Col>
      </Row>
      <Row>
        <Col span={6}>Son Güncelleme Tarihi:</Col>
        <Col span={6}>
          {dayjs(response.data.updatedAt).format("DD/MM/YYYY HH:mm")}
        </Col>
        <Col span={12} />
      </Row>
      <Table
        dataSource={dataSource}
        columns={thirdTabColumns}
        pagination={{ pageSize: 25, disabled: false }}
        scroll={{ x: 1500 }}
      />
    </div>
  );
};
