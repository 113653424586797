// #region Global Imports
import React, { FC } from "react";
import { Image, Card, Typography } from "@pankod/refine-antd";
import { CardProps } from "antd/lib/card";
// #endregion Global Imports

// #region Local Imports
// #region Local Imports

// #region Interface Imports
// #endregion Interface Imports

import "./index.css";

type Props<T = CardProps> = T & {
    isActive?: boolean;
    image: string;
    name: string;
};

export const Item: FC<Props> = ({ name, image, ...otherProps }) => {
    const { Paragraph } = Typography;

    return (
        <Card cover={
        <Image
            src={image}
            alt="example"
            style={{ padding: "1em", height: "200px", objectFit: "contain" }}
            loading="lazy"
            preview={false}
        />
        } {...otherProps} style={{ margin: 10, minHeight: 295 }}>
            <Paragraph
                style={{ marginBottom: 0, fontWeight: 500 }}
                ellipsis={{ rows: 2, tooltip: true }}
            >
                {name}
            </Paragraph>
        </Card>
    );
};
