import { Col, Row, Skeleton, Table, DatePicker } from "@pankod/refine-antd";
import { IResourceComponentsProps, useCustom } from "@pankod/refine-core";
import DownloadMenu from "components/DownloadDropdown";
import dayjs from "dayjs";
import React from "react";
import ENV from "utils/env";
import {
  exportToCsv,
  exportToExcel,
  exportToJson,
} from "utils/utiltyFunctions";
import { useDashboard } from "dashboardProvider";

export const TableMostAttendedDays: React.FC<IResourceComponentsProps> = () => {
  const [selectedDates, setSelectedDates] = React.useState<
    [Date, Date] | null
  >();

  const disabledDate = (current) => {
    // Disable future dates, but allow today
    return current && current > dayjs().endOf("day");
  };

  const { selectedDashboard } = useDashboard();

  const {
    data: response,
    isFetching,
    isError,
  } = useCustom({
    url: `${ENV.API_URL}/api/campaign/lottery/mostAttendedDays?dbkey=${selectedDashboard}`,
    method: "post",
    queryOptions: {
      retry: 0,
      enabled: true,
    },
    config: {
      payload: {
        startDate: selectedDates ? selectedDates[0] : null,
        endDate: selectedDates ? selectedDates[1] : null,
      },
    },
  });
  const handleChange = (date, dateArray) => {
    if (dateArray[0] !== "" && dateArray[1] !== "") {
      setSelectedDates(dateArray);
    } else {
      setSelectedDates(null);
    }
  };

  if (isFetching) {
    return <Skeleton />;
  }
  if (isError) {
    return (
      <div>
        <DatePicker.RangePicker
          placeholder={["Başlangıç Tarihi", "Bitiş Tarihi"]}
          onChange={handleChange}
          defaultValue={
            selectedDates
              ? [dayjs(selectedDates[0]), dayjs(selectedDates[1])]
              : null
          }
          disabledDate={disabledDate}
        />
        <div
          style={{ display: "flex", justifyContent: "center", marginTop: 16 }}
        >
          <span style={{ fontWeight: 800, fontSize: 20 }}>
            Bu tarih aralığına ait veri bulunamadı.
          </span>
        </div>
      </div>
    );
  }

  let dataSource = response.data?.days.map((i) => {
    return {
      key: dayjs(i.day).format("DD/MM/YYYY"),
      day: dayjs(i.day).format("DD/MM/YYYY"),
      count: i.game_count,
    };
  });
  const thirdTabColumns = [
    {
      title: "Gün",
      dataIndex: "day",
      key: "day",
      width: "25%",
    },
    {
      title: "Sayı",
      dataIndex: "count",
      key: "count",
      width: "25%",
    },
  ];

  const updatedAt = response.data?.updatedAt;
  const parsedUpdatedAt = dayjs(updatedAt).format("DD/MM/YYYY HH:mm");
  const formattedDate = updatedAt
    ? dayjs(updatedAt).format("DD-MM-YYYY-HH-mm")
    : "";

  return (
    <div>
      <DatePicker.RangePicker
        placeholder={["Başlangıç Tarihi", "Bitiş Tarihi"]}
        onChange={handleChange}
        defaultValue={
          selectedDates
            ? [dayjs(selectedDates[0]), dayjs(selectedDates[1])]
            : null
        }
        disabledDate={disabledDate}
      ></DatePicker.RangePicker>

      <Row justify="end" gutter={16}>
        <Col>
          <DownloadMenu
            dataSource={dataSource}
            columns={thirdTabColumns}
            formattedDate={formattedDate}
            exportToExcel={exportToExcel}
            exportToCsv={exportToCsv}
            exportToJson={exportToJson}
          />
        </Col>
      </Row>
      <Row>
        <Col span={6}>Son Güncelleme Tarihi:</Col>
        <Col span={6}>
          {dayjs(response.data.updatedAt).format("DD/MM/YYYY HH:mm")}
        </Col>
        <Col span={6} style={{ fontWeight: 600 }}>
          Veriler her 5 dakikada bir güncellenmektedir.
        </Col>
        <Col span={12} />
      </Row>
      <Table
        dataSource={dataSource}
        columns={thirdTabColumns}
        pagination={{ pageSize: 25, disabled: false }}
        scroll={{ x: 1500 }}
      />
    </div>
  );
};
