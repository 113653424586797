import { RoleTypes } from "interfaces/enums";
import { useGetIdentity } from "@pankod/refine-core";

/**
 * Returns role checking function to restrict
 * specific parts of application to user.
 * Admin roles always allowed. Only needs
 * allowed roles for the subject.
 */
export const useAuth = () => {
  const { data: user } = useGetIdentity();

  return (...authorizedRoles: RoleTypes[]) => {
    if (!user) return false;

    return [RoleTypes.SUPER_ADMIN, ...authorizedRoles].includes(user.role.type);
  };
};
