import { useState } from "react";
import QRCode from "qrcode.react";
import { Modal, Button, Icons } from "@pankod/refine-antd";
import { saveAs } from "file-saver";

import { Files } from "interfaces";
import ENV from "utils/env";

export const QRColumn = (_, file: Files.FileItem) => {
  const [visible, setVisible] = useState(false);

  const download = async () => {
    const canvas = document.querySelector<HTMLCanvasElement>(".js-qr > canvas");

    if (canvas) {
      canvas.toBlob((blob) => {
        if (blob) {
          saveAs(blob, `${file.id}.png`);
        }
      });
    }
  };

  const qrScale = 0.3;
  const qrSize = 720;
  const qrContainerSize = qrSize * qrScale;

  return (
    <>
      <Modal
        footer={false}
        title="QR Kodu Göster"
        visible={visible}
        onCancel={() => setVisible(false)}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            textAlign: "center",
          }}
        >
          <div
            className="js-qr"
            style={{
              width: qrContainerSize,
              height: qrContainerSize,
              alignSelf: "center",
              marginBottom: 12,
            }}
          >
            <QRCode
              style={{
                transform: `scale(${qrScale})`,
                transformOrigin: "top left",
              }}
              size={qrSize}
              id="canvas"
              value={`${ENV.API_URL}/api/qr/view-pdf/${file.id}`}
            />
          </div>
          <Button onClick={download} type="primary">
            <Icons.DownloadOutlined />
            QR Kodu İndir
          </Button>
        </div>
      </Modal>
      <Button onClick={() => setVisible(true)} type="primary" size="small">
        <Icons.QrcodeOutlined />
        QR Kodu Göster
      </Button>
    </>
  );
};
