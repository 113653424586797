import { file2Base64 } from "@pankod/refine-core";


import {
  Form,
  Input,
  Radio,
  Modal,
  ModalProps,
  FormProps,
  Upload,
  Row,
  Col,
  Icons,
  getValueFromEvent,
} from "@pankod/refine-antd";


type Props = {
  modalProps: ModalProps;
  formProps: FormProps;
};

export const FilesEdit: React.FC<Props> = ({
  formProps,
  modalProps,
}) => {
  const renderDraggerContent = () => {
    return (
      <div style={{ marginBottom: "112px", marginTop: "112px" }}>
        <p className="ant-upload-drag-icon">
          <Icons.InboxOutlined />
        </p>
        <p className="ant-upload-text">
          Yüklemek için dosyayı bu alana tıklayın veya sürükleyin
        </p>
      </div>
    );
  };

  const requestEdit = async (values: any) => {
    const { file: files } = values;

    let base64String;
    for (const file of files) {
      if (file.originFileObj) {
        base64String = await file2Base64(file);
      }
    }

    return (
      formProps.onFinish &&
      formProps.onFinish({
        ...values,
        file: base64String,
      })
    );
  };

  return (
    <Modal
      {...modalProps}
      width={800}
      title="Ürün Ekle"
      okText="Kaydet ve Kapat"
    >
      <Form
        {...formProps}
        onFinish={requestEdit}
        initialValues={{ isActive: true }}
        layout="vertical"
      >
        <Row gutter={[16, 16]}>
          <Col span={12}>
            <Form.Item>
              <Form.Item
                name="file"
                valuePropName="fileList"
                getValueFromEvent={getValueFromEvent}
                getValueProps={(data) => [data] as any}
                noStyle
                rules={[
                  {
                    required: true,
                    message: "Dosya görseli boş olamaz.",
                  },
                ]}
              >
                <Upload.Dragger
                  accept=".pdf"
                  showUploadList
                  beforeUpload={() => false}
                  multiple={false}
                  maxCount={1}
                >
                  {renderDraggerContent()}
                </Upload.Dragger>
              </Form.Item>
            </Form.Item>
            <span
              style={{
                fontSize: "12px",
                color: "#8C8C8C",
                fontWeight: 300,
                letterSpacing: "0.5px",
              }}
            >
              min
              <strong> 1080x1080 </strong>
              transparan
              <strong> .png </strong>
              olmalı
            </span>
          </Col>
          <Col span={12}>
            <Form.Item
              label="Başlık"
              name="title"
              validateTrigger="onBlur"
              rules={[
                {
                  required: true,
                  message: "Dosya ismi alanı boş olamaz.",
                },
              ]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              label="Açıklama"
              name="description"
              validateTrigger="onBlur"
            >
              <Input.TextArea />
            </Form.Item>
            <Form.Item
              label="Durumu"
              name="isActive"
              rules={[
                {
                  required: true,
                  message: "Dosya durumunu seçiniz.",
                },
              ]}
            >
              <Radio.Group
                defaultValue={1}
                options={[
                  {
                    label: "Aktif",
                    value: true,
                  },
                  {
                    label: "Pasif",
                    value: false,
                  },
                ]}
              />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
};
