import * as XLSX from 'xlsx';
import { saveAs } from 'file-saver';

export const exportToExcel = (data, columns, filename) => {
  const workbook = XLSX.utils.book_new();
  const worksheetData = [
    columns.map(col => col.header),
    ...data.map(row => columns.map(col => row[col.key]))
  ];

  const worksheet = XLSX.utils.aoa_to_sheet(worksheetData);

  XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');

  const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
  const blob = new Blob([excelBuffer], { type: 'application/octet-stream' });
  saveAs(blob, filename);
};

export const exportToJson = (data, filename) => {
  const json = JSON.stringify(data, null, 2);
  const blob = new Blob([json], { type: 'application/json' });
  saveAs(blob, filename);
};


export const exportToCsv = (data, columns, filename) => {
  const worksheetData = [
    columns.map(col => col.header),
    ...data.map(row => columns.map(col => row[col.key]))
  ];
  const worksheet = XLSX.utils.aoa_to_sheet(worksheetData);
  const csv = XLSX.utils.sheet_to_csv(worksheet);
  const bom = '\uFEFF';
  const csvWithBom = bom + csv;
  const blob = new Blob([csvWithBom], { type: 'text/csv;charset=utf-8;' });
  saveAs(blob, filename);
};