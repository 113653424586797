import { Col, Row, Skeleton, Table } from "@pankod/refine-antd";
import { IResourceComponentsProps, useCustom } from "@pankod/refine-core";
import DownloadMenu from "components/DownloadDropdown";
import dayjs from "dayjs";
import ENV from "utils/env";
import {
  exportToCsv,
  exportToExcel,
  exportToJson,
} from "utils/utiltyFunctions";
import { useDashboard } from "dashboardProvider";
const secondTabColumns = [
  {
    header: "Mevcut Tim Kodu",
    key: "key",
  },
  {
    header: "Turkcell Adı",
    key: "turkcellName",
  },
  {
    header: "Turkcell Email",
    key: "turkcellMail",
  },
  {
    header: "Telefon",
    key: "phoneNumber",
  },
  {
    header: "Kontakt Adı",
    key: "contactName",
  },
  {
    header: "Kontakt Numarası",
    key: "contactGsm",
  },
  {
    header: "Email",
    key: "email",
  },
  {
    header: "Şehir",
    key: "sehir",
  },
  {
    header: "İlçe",
    key: "ilce",
  },
];

export const TableUnplayedBrachDetails: React.FC<
  IResourceComponentsProps
> = () => {
  const { selectedDashboard } = useDashboard();

  const { data: response, isFetching } = useCustom({
    url: `${ENV.API_URL}/api/campaign/trivia/unplayedBranchDetails`,
    method: "get",
    config: {
      query: {
        dbkey: selectedDashboard,
      },
    },
  });

  if (isFetching) {
    return <Skeleton />;
  }

  const dataSource = response.data.branchDetails.map((item: any) => {
    return {
      key: item["shopCode"],
      name: item["name"],
    };
  });

  const columns = [
    {
      title: "Mevcut Tim Kodu",
      dataIndex: "key",
      key: "key",
      width: "25%",
    },
    {
      title: "Turkcell Adı",
      dataIndex: "name",
      key: "name",
      width: "25%",
    },
  ];

  const updatedAt = response.data.updatedAt;
  const parsedUpdatedAt = dayjs(updatedAt).format("DD/MM/YYYY HH:mm");
  const formattedDate = updatedAt
    ? dayjs(updatedAt).format("DD-MM-YYYY-HH-mm")
    : "";

  return (
    <div>
      <Row justify="end" gutter={16}>
        <Col>
          <DownloadMenu
            dataSource={dataSource}
            columns={secondTabColumns}
            formattedDate={formattedDate}
            exportToExcel={exportToExcel}
            exportToCsv={exportToCsv}
            exportToJson={exportToJson}
          />
        </Col>
      </Row>
      <Row>
        <Col span={6}>Oynanan Bayi Sayısı</Col>
        <Col span={6}>
          <b>{response.data.playedBranchesCount}</b>
        </Col>
        <Col span={12} />
      </Row>
      <Row>
        <Col span={6}>Oynanmayan Bayi Sayısı</Col>
        <Col span={6}>
          <b>{response.data.unPlayedBranchesCount}</b>
        </Col>
        <Col span={12} />
      </Row>
      <Row>
        <Col span={6}>Son Güncelleme Tarihi:</Col>
        <Col span={6}>
          {dayjs(response.data.updatedAt).format("DD/MM/YYYY HH:mm")}
        </Col>
        <Col span={12} />
      </Row>
      <Table
        dataSource={dataSource}
        columns={columns}
        pagination={{ pageSize: 25, disabled: false }}
        scroll={{ x: 1500 }}
      />
    </div>
  );
};
