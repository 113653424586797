import React, { useState, useCallback } from "react";
import {
  useTranslate,
  useCustom,
  HttpError,
  useLogin,
} from "@pankod/refine-core";
import {
  Card,
  Form,
  Input,
  Button,
  Typography,
  notification,
  Statistic,
} from "@pankod/refine-antd";
import dayjs from "dayjs";
import { useEffect } from "react";

import "./styles.scss";
import { Auth } from "interfaces";
import ENV from "utils/env";

const { Title } = Typography;
const { Countdown } = Statistic;

export interface ILoginForm {
  gsm: string;
  code: string;
}

export const Login: React.FC = () => {
  const [form] = Form.useForm<Auth.CodeRequest>();
  const [gsmNumber, setGsmNumber] = useState<string>("");
  const [code, setCode] = useState<string>("");

  const [current, setCurrent] = useState<"gsmNumber" | "code">("gsmNumber");

  const [countdown, setCountdown] = useState(0);

  useEffect(() => {
    if (current === "code") {
      setCountdown(dayjs().add(5, "minute").valueOf());
    }
  }, [current]);

  const { mutate: login } = useLogin();

  const { refetch: codeRequest, isFetching: codeRequestIsFetching } =
    useCustom<{ code: string }>({
      url: `${ENV.API_URL}/api/auth/code`,
      method: "post",
      config: {
        payload: {
          gsm: gsmNumber,
        },
      },
      queryOptions: {
        enabled: false,
        retry: false,
      },
    });

  const { refetch: loginRequest, isFetching: loginRequestIsFetching } =
    useCustom<Auth.UserResponse, HttpError, unknown, Auth.LoginRequest>({
      url: `${ENV.API_URL}/api/auth/login`,
      method: "post",
      config: {
        payload: {
          gsm: gsmNumber,
          code: code,
        },
      },
      queryOptions: {
        enabled: false,
        retry: false,
      },
    });

  const t = useTranslate();

  const requestAuthCode = async ({ gsm }: Auth.CodeRequest) => {
    if (current === "gsmNumber") {
      const { data, isSuccess } = await codeRequest();

      if (isSuccess) {
        if (
          window.location.host !==
            "prod.backoffice.turkcell.live.poster.puul.tv" &&
          window.location.host !== "ekranim.puul.tv" &&
          window.location.host !== "turkcell.ekranim.com.tr" &&
          window.location.host !== "turkcell-test.ekranim.com.tr" &&
          window.location.host !== "turkcell-ekranim-backoffice.madde22.xyz"
        ) {
          notification.info({
            key: "DEVELOPMENT_ONLY",
            message: "Giriş Kodu",
            description: data?.data?.code,
          });
        }

        setCurrent("code");

        notification.info({
          key: "SMS_SEND_SUCCESS",
          message: "Giriş Kodu",
          description: renderInfoMessage(),
          duration: 0,
        });
      }
    } else if (current === "code") {
      const { data, isSuccess } = await loginRequest();

      if (isSuccess) {
        login(data.data);

        notification.close("SMS_SEND_SUCCESS");
        notification.close("DEVELOPMENT_ONLY");
      }
    }
  };

  const timerOnFinish = () => {
    form.resetFields();

    notification.error({
      message: "Verilen sürede şifreyi girmediniz. Lütfen yeniden şifre alın.",
    });

    setCurrent("gsmNumber");
  };

  const renderInfoMessage = useCallback(() => {
    if (!gsmNumber) return;

    const friendlyGSM = gsmNumber.replace(
      /^(\d{3})(\d{3})(\d{2})(\d{2})$/,
      "$1 $2 $3 $4"
    );

    return (
      <span>
        <strong>{`${friendlyGSM} `}</strong>
        numarasına gönderildi. Değiştirmek için
        <button
          type="button"
          style={{ marginLeft: "4px" }}
          onClick={() => {
            form.resetFields();
            setCurrent("gsmNumber");
            notification.close("SMS_SEND_SUCCESS");
            notification.close("DEVELOPMENT_ONLY"); // TODO: Remove prod.
          }}
        >
          tıklayın.
        </button>
      </span>
    );
  }, [gsmNumber, form]);

  const renderGsmForm = (
    <>
      <span>{t("common:login.code.gsm.label")}</span>
      <Form.Item
        name="gsm"
        validateTrigger="onBlur"
        style={{ marginBottom: 15 }}
        rules={[
          {
            required: true,
            message: t("common:login.code.gsm.validation.required"),
          },
          {
            type: "string",
            message: t("common:login.code.gsm.validation.type"),
            len: 10,
          },
        ]}
      >
        <Input
          maxLength={10}
          placeholder="5_________"
          className="my-1"
          size="large"
          onChange={(event) => setGsmNumber(event.target.value)}
        />
      </Form.Item>

      <Button
        block
        type="primary"
        size="large"
        shape="round"
        htmlType="submit"
        loading={codeRequestIsFetching}
      >
        {t(`common:login.code.submit`)}
      </Button>
    </>
  );

  const renderCodeForm = (
    <>
      <span>{t("common:login.password.code.label")}</span>
      <Form.Item
        name="code"
        validateTrigger="onBlur"
        style={{ marginBottom: 6 }}
        rules={[
          {
            required: true,
            message: t("common:login.password.code.validation.required"),
          },
          {
            len: 6,
            message: t("common:login.password.code.validation.length"),
          },
        ]}
      >
        <Input.Password
          name="code"
          size="large"
          maxLength={6}
          onChange={(event) => setCode(event.target.value)}
        />
      </Form.Item>

      <Countdown
        format="mm:ss"
        value={countdown}
        style={{ marginBottom: 6 }}
        onFinish={timerOnFinish}
        valueStyle={{
          color: "#8C8C8C",
          fontSize: "12px",
        }}
      />

      <Button
        block
        type="primary"
        size="large"
        shape="round"
        htmlType="submit"
        loading={loginRequestIsFetching}
      >
        {t(`common:login.password.submit`)}
      </Button>
    </>
  );

  return (
    <div className="container-fluid">
      <div className="center">
        <img src="/images/turkcell-screen-logo.svg" alt="Turkcell" />

        <div className="title">{t(`common:login.title`)}</div>

        <div className="children-outlet">
          <div className="ripples">
            <div className="ripple">
              <div className="ripple">
                <div className="ripple magma" />
              </div>
            </div>
          </div>

          <Card>
            <Title
              level={4}
              style={{ textAlign: "center", paddingLeft: 24, paddingRight: 24 }}
              className="text-center px-6"
            >
              {t("common:login.code.title")}
            </Title>

            <Form
              form={form}
              name="basic"
              layout="vertical"
              onFinish={requestAuthCode}
            >
              {current === "gsmNumber" ? renderGsmForm : renderCodeForm}
            </Form>
          </Card>
        </div>
      </div>
    </div>
  );
};
