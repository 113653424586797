import { IResourceComponentsProps, useCustom } from "@pankod/refine-core";
import React from "react";

import { AntdList, Skeleton } from "@pankod/refine-antd";
import ENV from "utils/env";
import { useDashboard } from "dashboardProvider";
export const TopFiveBranchList: React.FC<IResourceComponentsProps> = () => {
  const { selectedDashboard } = useDashboard();
  const {
    data: response,
    isFetching,
    isError,
  } = useCustom({
    url: `${ENV.API_URL}/api/campaign/lottery/mostAttendedBranchDetails`,
    method: "get",
    config: {
      query: {
        dbkey: selectedDashboard,
      },
    },
  });

  if (isFetching) {
    return <Skeleton />;
  }
  if (isError) {
    return (
      <span style={{ fontWeight: 800, fontSize: 20 }}>Veri bulunamadı.</span>
    );
  }

  const dataSource: any = response.data.filteredBranches
    .slice(0, 5) // Get the first 5 elements
    .map((item: any) => {
      return {
        key: item["shopCode"],
        name: item["name"],
        count: item["count"],
      };
    });

  return (
    <div>
      <AntdList
        dataSource={dataSource}
        header=" "
        renderItem={(item: any, index) => (
          <AntdList.Item>
            <AntdList.Item.Meta
              title={item.name}
              description={`TIM KODU: ${item.key}`}
            ></AntdList.Item.Meta>
            <p style={{ fontWeight: 800, fontSize: 20 }}>{item.count}</p>
          </AntdList.Item>
        )}
      ></AntdList>
    </div>
  );
};
