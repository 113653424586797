import { Select } from "@pankod/refine-antd";
const { Option } = Select;

interface CitySelectorProps {
  selectedCity: string | null;
  setSelectedCity: React.Dispatch<React.SetStateAction<string>>;
}

export const CitySelector: React.FC<CitySelectorProps> = ({
  selectedCity,
  setSelectedCity,
}) => {
  const handleChange = (key: string) => {
    setSelectedCity(key);
  };

  const menuItems = [
    { key: "ADIYAMAN", label: "Adıyaman" },
    { key: "AFYON", label: "Afyon" },
    { key: "AGRI", label: "Ağrı" },
    { key: "AMASYA", label: "Amasya" },
    { key: "ANKARA", label: "Ankara" },
    { key: "ARTVIN", label: "Artvin" },
    { key: "ARDAHAN", label: "Ardahan" },
    { key: "BALIKESIR", label: "Balıkesir" },
    { key: "BARTIN", label: "Bartın" },
    { key: "BATMAN", label: "Batman" },
    { key: "BAYBURT", label: "Bayburt" },
    { key: "BILECIK", label: "Bilecik" },
    { key: "BINGOL", label: "Bingöl" },
    { key: "BOLU", label: "Bolu" },
    { key: "BURDUR", label: "Burdur" },
    { key: "BURSA", label: "Bursa" },
    { key: "CANAKKALE", label: "Çanakkale" },
    { key: "CANKIRI", label: "Çankırı" },
    { key: "CORUM", label: "Çorum" },
    { key: "DENIZLI", label: "Denizli" },
    { key: "DIYARBAKIR", label: "Diyarbakır" },
    { key: "DUZCE", label: "Düzce" },
    { key: "EDIRNE", label: "Edirne" },
    { key: "ELAZIG", label: "Elazığ" },
    { key: "ERZINCAN", label: "Erzincan" },
    { key: "ERZURUM", label: "Erzurum" },
    { key: "GaziANTEP", label: "Gaziantep" },
    { key: "GIRESUN", label: "Giresun" },
    { key: "GIRNE", label: "Girne" },
    { key: "HATAY", label: "Hatay" },
    { key: "HAKKARI", label: "Hakkari" },
    { key: "IGDIR", label: "Iğdır" },
    { key: "ISPARTA", label: "Isparta" },
    { key: "ISTANBUL", label: "İstanbul" },
    { key: "IZMIR", label: "İzmir" },
    { key: "KARAMAN", label: "Karaman" },
    { key: "KARABUK", label: "Karabük" },
    { key: "KARS", label: "Kars" },
    { key: "KASTAMONU", label: "Kastamonu" },
    { key: "KIRIKKALE", label: "Kırıkkale" },
    { key: "KIRSEHIR", label: "Kırşehir" },
    { key: "KOCAELI", label: "Kocaeli" },
    { key: "KONYA", label: "Konya" },
    { key: "KUTAHYA", label: "Kütahya" },
    { key: "LEFKOSE", label: "Lefkoşa" },
    { key: "MANISA", label: "Manisa" },
    { key: "MARDIN", label: "Mardin" },
    { key: "MALATYA", label: "Malatya" },
    { key: "MERSIN", label: "Mersin" },
    { key: "MUGLA", label: "Muğla" },
    { key: "MUS", label: "Muş" },
    { key: "NEVSEHIR", label: "Nevşehir" },
    { key: "NIGDE", label: "Niğde" },
    { key: "ORDU", label: "Ordu" },
    { key: "RIZE", label: "Rize" },
    { key: "SAKARYA", label: "Sakarya" },
    { key: "SAMSUN", label: "Samsun" },
    { key: "SANLIURFA", label: "Şanlıurfa" },
    { key: "SIIRT", label: "Siirt" },
    { key: "SINOP", label: "Sinop" },
    { key: "SIVAS", label: "Sivas" },
    { key: "SIRNAK", label: "Şırnak" },
    { key: "TEKIRDAG", label: "Tekirdağ" },
    { key: "TOKAT", label: "Tokat" },
    { key: "TUNCELI", label: "Tunceli" },
    { key: "TRABZON", label: "Trabzon" },
    { key: "USAK", label: "Uşak" },
    { key: "VAN", label: "Van" },
    { key: "YOZGAT", label: "Yozgat" },
    { key: "YALOVA", label: "Yalova" },
    { key: "ZONGULDAK", label: "Zonguldak" },
  ];
  return (
    <div>
      <Select
        value={selectedCity || undefined}
        placeholder="Şehir Seçiniz."
        style={{ width: "auto" }}
        allowClear
        onChange={(e) => handleChange(e)}
      >
        {menuItems.map((item) => (
          <Option key={item.key} value={item.key}>
            <span>{item.label}</span>
          </Option>
        ))}
      </Select>
    </div>
  );
};
